import React from 'react'
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/effect-cards';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation, EffectCards } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import Footer from '../Footer';


const LandingPage = () => {
    return (
        <>
            {/* <!-- header area end --> */}
            {/* <!-- hero area start --> */}
            <div className="hero-area slider-1 parallax" id="slider-area">
                <div className="slider">
                    <div className="container">
                        <div className="row align-items-center ">
                            <div className=" col-lg-6">
                                <img src="img/main_img.svg" className="img-fluid main_img" />
                            </div>
                            <div className=" col-lg-6 ">
                                <div className="hero-text mr-ri-l">
                                    <h1 className=" "> <b> Your <span className='text_gradient' >Ultimate Ludo</span>, Adventure Awaits!</b> </h1>
                                    <h5 className="text-dark">
                                        Play, Compete, and Connect in Real-Time. Join Millions of Players and Climb the Leaderboard!
                                    </h5>
                                    <a href="https://api.zamppa.com/downloads" download="/downloads" className="hero-btn"> <i className="icofont icofont-download-alt me-2"></i>
                                        Download the APP Now </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- hero area end --> */}
            <div className="dn_row">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12">
                            <div className="down_row d-flex justify-content-start">
                                <div className="down_data me-md-5 pe-md-5">
                                    <h3>India's No.1 Most Safest Ludo App</h3>
                                </div>
                                <div className="down_data me-md-5 pe-md-5 d-flex align-items-center">
                                    <img src="img/users.png" className="img-fluid" width="60" alt="Users" />
                                    <div className="ms-2">
                                        <h3>
                                            1 million +
                                            <small className="d-block">Trusted Users</small>
                                        </h3>
                                    </div>
                                </div>
                                <div className="down_data d-flex align-items-center">
                                    <img src="img/crown.png" className="img-fluid" width="60" alt="Crown" />
                                    <div className="ms-2">
                                        <h3>
                                            1 crore +
                                            <small className="d-block">Daily Winnings</small>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About area start --> */}
            <section id="about" className="about-area pt-130">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="about-content">
                                <h2>Play Ludo Online and Win Real Money on Zamppa </h2>
                                <p>
                                    Zamppa is the most entertaining online platform for the real-money game Ludo. With challenging strategies, Ludo has become more enjoyable than ever.
                                </p>
                                <p>Ludo at Zamppa will test and challenge players' skills while they have fun and win real cash. Move your fingers now to win real money!</p>
                                <a className="hero-btn" href="https://api.zamppa.com/downloads" >
                                    <i className="icofont icofont-download-alt me-2"></i>
                                    Download the App Now
                                </a>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-img">
                                <img src="img/about_img.png" className="img-fluid" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- service area start --> */}
            <section id="features" className="service-area ">
                <div className="container">


                    <div className="row align-items-center justify-content-center">
                        <div className="col-md-8">
                            <div className="section-heading w-100 pb-55 sec_title text-center">
                                <h2> Why Choose Zamppa? </h2>
                            </div>
                        </div>

                        <div className="row mt-5">
                            <div className="col-lg-3 mbm_50">
                                <div className="single-service service1">
                                    <div className="service-icon">
                                        <img src="img/gf_1.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>01</span>
                                        <h2> Instant Cash Withdrawal </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mtm_50">
                                <div className="single-service ">
                                    <div className="service-icon">
                                        <img src="img/gf_2.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>02</span>
                                        <h2> 100% Secure and Legal</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mtm_50 ">
                                <div className="single-service   ">
                                    <div className="service-icon">
                                        <img src="img/gf_3.png" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>03</span>
                                        <h2>Daily Rewards and Bounces</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mbm_50">
                                <div className="single-service   " >
                                    <div className="service-icon">
                                        <img src="img/gf_4.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>04</span>
                                        <h2>Strategic Gameplay
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mbm_50">
                                <div className="single-service " >
                                    <div className="service-icon">
                                        <img src="img/gf_5.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>05</span>
                                        <h2> New Experience
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mtm_50">
                                <div className="single-service  " >
                                    <div className="service-icon">
                                        <img src="img/gf_6.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>06</span>
                                        <h2>24/7 Customer Support
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 mtm_50">
                                <div className="single-service  " >
                                    <div className="service-icon">
                                        <img src="img/gf_7.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>07</span>
                                        <h2> Unique Twist
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3  mbm_50">
                                <div className="single-service " >
                                    <div className="service-icon">
                                        <img src="img/gf_8.svg" width="100" className="img-fluid" alt="" />
                                        <a href='#' className='btn btn-icon'> <i class="icofont icofont-arrow-up "></i> </a>
                                    </div>
                                    <div className="service-content">
                                        <span>08</span>
                                        <h2> Competitive Gameplay
                                        </h2>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </section>
            {/* <!-- service area start --> */}

            <div className="bg_img" >

                {/* <!-- Pricing Plan Area Start --> */}
                <section id="games" className="pricing-area pt-130 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="section-heading pb-55 text-center">
                                    <h2>Play and Win Real Cash - Games We Offer</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-3 col-md-6 my-3">
                                <img src="/img/classic.png" className="img-fluid" />
                            </div>
                            <div className="col-lg-3 col-md-6 my-3">
                                <img src="/img/express.png" className="img-fluid" />
                            </div>
                            <div className="col-lg-3 col-md-6 my-3">
                                <img src="/img/ultra.png" className="img-fluid" />
                            </div>
                            {/* <div className="col-lg-3 col-md-6 my-3">
                                <img src="/img/BlindMove.png" className="img-fluid" />
                            </div> */}
                        </div>
                    </div>
                </section>

            </div>



            <section className="feature-area area_bg">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading pb-55 text-center">
                                <h2>How to Download Zamppa App?
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6">
                            <div className="awesome-feature awesome-feature_step_1">
                                <div className="awesome-feature-icon">
                                    <span><i className=""> <b>1</b> </i></span>
                                    <p> Click on the <b>“File Download Section”</b> and Check the Zamppa App. </p>
                                </div>
                                <div className="awesome-feature-details" >
                                    <img src="img/step_1.svg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="awesome-feature awesome-feature_step_1">
                                <div className="awesome-feature-icon">
                                    <span><i className=""> <b>2</b> </i></span>
                                    <p> Click on the <b>“Download Now”</b> Button. </p>
                                </div>
                                <div className="awesome-feature-details" >
                                    <img src="img/step_2.svg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="awesome-feature awesome-feature_step_1">
                                <div className="awesome-feature-icon">
                                    <span><i className=""> <b>3</b> </i></span>
                                    <p> Click on the <b>“Download Symbol”</b> to begin the installation. </p>
                                </div>
                                <div className="awesome-feature-details" >
                                    <img src="img/step_3.svg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <div className="awesome-feature awesome-feature_step_1">
                                <div className="awesome-feature-icon">
                                    <span><i className=""> <b>4</b> </i></span>
                                    <p> Go to settings and click <b>“Allow From This Source”</b> when prompted.  </p>
                                </div>
                                <div className="awesome-feature-details" >
                                    <img src="img/step_4.svg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Feature feature area end --> */}
            {/* <!-- Team Area End --> */}
            {/* <!-- Subcribe Area Start --> */}
            <section id="faq" className="subcribe-area pt-115 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-heading pb-35 text-center">
                                <h2> <span className="text_gradient" > FAQs on Online Ludo Game </span></h2>
                            </div>
                        </div>
                    </div>
                    <div id="accordionFlushExample" className="accordion accordion-flush">
                        <div className="row">

                            <div className="col-lg-6">
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="feed_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#feed_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span> How to Download Zamppa Ludo App? </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="feed_onea" className="accordion-collapse collapse" aria-labelledby="feed_one" data-bs-parent="#accordionFlushExample"  >

                                        <div className="acc_body" >
                                            <h5>
                                                In Android
                                            </h5>
                                            <ul>
                                                <li>                                                 Click on the “Download Now” Button. </li>
                                                <li>    Click on the “File Download Section” and Check the Zamppa App. </li>
                                                <li>    Click on the “Download Symbol” to begin the installation. </li>
                                                <li>    Go to settings and click “Allow From This Source” when prompted.  </li>
                                                <li>     Locate the app in downloads. </li>
                                                <li>     Click on the APK and start the installation on the phone. </li>

                                            </ul>

                                            <br />

                                            <h5>
                                                In iOS
                                            </h5>
                                            <ul>
                                                <li>  Open the App Store on your iOS device.</li>
                                                <li>  Search Zamppa App </li>
                                                <li>  Click on the “Get” Button.</li>
                                                <li>  Once Downloaded, locate it on the home screen.</li>
                                                <li>  Sign in and start winning the prizes. </li>


                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="two_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#two_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span> How to Play Ludo Online and Win Cash? </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="two_onea" className="accordion-collapse collapse" aria-labelledby="two_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            <p>Well, it’s easy with the Zamppa Ludo App:</p>
                                            <br />
                                            <p> <b>Register-</b> Create your profile by including basic details such as name and email address.</p>
                                            <p> <b> Select and Play-</b> Select and play Ludo in the Zamppa App and show your skills.</p>
                                            <p>  <b>Win and Withdraw Real Money-</b> When you play and win money, it gets credited to your Zamppa wallet. You can withdraw it to your bank account any time you want.</p>

                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="three_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#three_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span> Why You Should Play Real Cash Ludo Online on Zamppa? </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="three_onea" className="accordion-collapse collapse" aria-labelledby="three_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            <p>Zamppa is a trusted real-money Ludo platform offering anytime withdrawal facilities.
                                            </p>

                                            <p> <b>Game Choices-</b> You can play Ludo and get a chance to win real-cash prices. This game has strategic twists to challenge your skills. </p>
                                            <p>  <b>Safe Transactions-</b> Zamppa secures all your transactions and has a hassle-free experience for Android and iOS users. </p>
                                            <p>  <b> Bonus and Rewards-</b> Zamppa offers joining bonuses and exciting rewards. </p>


                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="four_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#four_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>  Is It Safe to Play Ludo Online on Zamppa? </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="four_onea" className="accordion-collapse collapse" aria-labelledby="four_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Yes, it is 100% safe to play Ludo on Zamppa. It is registered under (write name if any government-authorized certification is available). Zamppa provides a safe and hassle-free space for all transactions. All your personal and payment data is safe with Zamppa. Plus, this game complies with every Indian Government policy, making it the safest real-money Ludo game.
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="five_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#five_onea" aria-expanded="false" aria-controls="feed_onea">
                                            <span>  How to Deposit Money on Zamppa? </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="five_onea" className="accordion-collapse collapse" aria-labelledby="five_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            <p>Here’s how to add money in Ladudo quickly and safely:
                                            </p>

                                            <ul>
                                                <li>    Sign in to Your Zamppa Account </li>
                                                <li>    Find the ‘Add Cash’ Section </li>
                                                <li>    Enter Amount </li>
                                                <li>    Choose Payment Method </li>
                                                <li>    Complete the Transaction </li>

                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">

                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="six_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#six_onea" aria-expanded="false" aria-controls="six_onea">
                                            <span>
                                                Is Online Real Cash Ludo Worth Playing?</span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="six_onea" className="accordion-collapse collapse" aria-labelledby="six_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            Yes, it sure is worth it. If you are a fan of challenging games, these real cash Ludo will offer you a chance to win real prizes. Ensure to keep a balance between the amount you invested in the game to make it a fun source of income.

                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="seven_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#seven_onea" aria-expanded="false" aria-controls="seven_onea">
                                            <span> How Can I Withdraw My Winnings From Zamppa?</span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="seven_onea" className="accordion-collapse collapse" aria-labelledby="seven_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >
                                            <p>If you are thinking “Zamppa se paisa kaise nikale”, here are the steps:</p>

                                            <ul>
                                                <li>Go to wallet: Click on the withdraw money section on Zamppa. Check your eligible real money Ludo winnings to withdraw.</li>
                                                <li> Enter the amount & payment method: Enter the amount that you want to withdraw and choose payment methods among UPI, bank transfer, or card transfer.</li>
                                                <li>Complete KYC verification: You need to verify your bank or UPI info with an Aadhar/PAN card using KYC verification to withdraw money from Zamppa.</li>
                                                <li> Complete Withdraw: Enter the OTP sent to your phone and you are done. Your withdrawal will be reflected in your source within 48 hours.</li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="eight_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#eight_onea" aria-expanded="false" aria-controls="nine_onea">
                                            <span> How Can I Improve My Chances Of Winning Online Cash Ludo?
                                            </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="eight_onea" className="accordion-collapse collapse" aria-labelledby="eight_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >

                                            <ul>
                                                <li> Play free games: Before going live in a game with real money, play free games to learn some strategies. </li>
                                                <li>  Practice: Practice regularly to understand game dynamics. </li>
                                                <li>  Study the game: Every Ludo game in Zamppa has different challenges and learn strategies to win all of them. </li>
                                                <li>   Stay focused: While playing the game, stay focused to avoid any mistakes. </li>
                                                <li>   Set a budget: Do not overspend on a game. Before entering the game, set a budget that does not harm your pocket. </li>
                                            </ul>


                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="nine_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#nine_onea" aria-expanded="false" aria-controls="nine_onea">
                                            <span> How Long Does It Take To Withdraw Money From Online Ludo Game Zamppa?
                                            </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="nine_onea" className="accordion-collapse collapse" aria-labelledby="nine_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body" >

                                            As soon as you transfer the money to your source account from Zamppa, the withdrawal is complete. However, it might take 48 hours to reflect the amount in your account.

                                        </div>

                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h5 className="accordion-header" id="ten_one">
                                        <button className="button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#ten_onea" aria-expanded="false" aria-controls="nine_onea">
                                            <span> Is It Safe To Deposit Money Into Online Game Wallets?
                                            </span>
                                            <i className="fa fa-angle-down"></i>
                                        </button>
                                    </h5>
                                    <div id="ten_onea" className="accordion-collapse collapse" aria-labelledby="ten_one" data-bs-parent="#accordionFlushExample">

                                        <div className="acc_body">

                                            Yes, it is safe to deposit money into the Zamppa wallet. Encryption and strict regulations ensure that all your financial details are safe with Zamppa.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </section >
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default LandingPage

