import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../Footer";

const ContactUs = () => {


    const [inputData, setinputData] = useState({ name: '', email: '', subject: '', detail: '' });
    const [attachments, setAttachments] = useState();
    const location = useLocation();
    const isMobSupport = location?.pathname?.includes('/mobSupport');

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setinputData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };



    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> CONTACT US   </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> CONTACT US   </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">

                <section id="contact" class="contact">
                    <div class="container">
                        <div class="section-title aos-init aos-animate section-heading w-100 pb-55" data-aos="fade-up">
                            <h2 className='text_gradient' > Contact Us  </h2>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-lg-12 aos-init aos-animate" data-aos="fade-right" data-aos-delay="100">
                                <div class="info row align-items-start">
                                    <div class="email col-md-12 mt-md-0"><i class="bi bi-envelope"></i>
                                        <h4>Email:</h4>
                                        <p>support@zamppa.com</p>
                                    </div>

                                    <br></br>
                                    <br></br>
                                    <div class="email col-md-12 mt-md-0"><i class="bi bi-envelope"></i>
                                        <h4>Contact No.:</h4>
                                        <p>+91 626 622 3656</p>
                                    </div>

                                    <div class="address col-md-4"><i class="bi bi-geo-alt"></i>
                                        <h4> Location: </h4>
                                        <p> Registered And corporate Office :-<br></br>

                                            Captain Kraft Technology Private Limited <br></br>

                                            1st Floor, A wing , Sukhamani Building , Near Sky Park New Ranibagh,<br></br>
                                            Indore 452020<br></br>
                                            Madhya Pradesh India </p>
                                        <div class="phone  col-md-4  mt-md-0"><i class="bi bi-phone"></i>
                                            {/* <h4>Call:</h4> */}
                                            {/* <p> +91 0000 000 000 </p> */}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />


                    </div>
                </section>
            </section>
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default ContactUs
