import React from 'react'
import Footer from '../Footer'

const AboutUs = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> About Us </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> About us </li>
                        </ol>
                    </nav>
                </div>
            </div>


            <main className='about_sec' >
                <section class=" container ab_sec">
                    <div className='row align-items-center' >
                        <div className='col-lg-7' >
                            <div class=" undefined">
                                <h2 className='pp_title'>We Made Your Childhood Nostalgia Exciting with Real-Money</h2></div>
                        </div>
                        <div className='col-lg-5' >
                            <img src="img/about_img.png" class="img-fluid" alt="" />
                        </div>
                    </div>

                </section>
                <section class=" container">
                    <div className='row' >
                        <div className='col-lg-12' >
                            <div class=" undefined">
                                <h2 className='pp_title mt-0'> Who We Are?  </h2>
                            </div>
                            <div class=" disc-para">
                                <div class=" regular--text trim-Regular m-0">
                                    We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="section-team">
                    <div class="container">
                        {/* <!-- Start Header Section --> */}
                        <div class="row justify-content-center text-center">
                            <div class="col-md-8 col-lg-6">
                                <div class="header-section">
                                    <div class=" undefined">
                                        <h2 className='pp_title mt-0 text-center'> Our Dream Team </h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- / End Header Section --> */}
                        <div class="row">
                            {/* <!-- Start Single Person --> */}
                            <div class="col-sm-6 col-lg-4 col-xl-3">
                                <div class="single-person">
                                    <div class="person-image">
                                        <img src="/img/mrsankalp.png" alt="" />
                                    </div>
                                    <div class="person-info">
                                        <h3 class="full-name">Mr. SANKALP SONI</h3>
                                        <span class="speciality"> Founder </span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- / End Single Person --> */}
                            {/* <!-- Start Single Person --> */}
                            <div class="col-sm-6 col-lg-4 col-xl-3">
                                <div class="single-person">
                                    <div class="person-image">
                                        <img src="/img/mrneeraj.png" alt="" />
                                    </div>
                                    <div class="person-info">
                                        <h3 class="full-name">Mr. NEERAJ SONI</h3>
                                        <span class="speciality">  CEO  </span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- / End Single Person --> */}
                            {/* <!-- Start Single Person --> */}
                            <div class="col-sm-6 col-lg-4 col-xl-3">
                                <div class="single-person">
                                    <div class="person-image">
                                        <img src="/img/mrsuresh.png" alt="" />
                                    </div>
                                    <div class="person-info">
                                        <h3 class="full-name">Mr. SURESH SONI </h3>
                                        <span class="speciality"> Other Team Member</span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- / End Single Person --> */}
                            {/* <!-- Start Single Person --> */}
                            <div class="col-sm-6 col-lg-4 col-xl-3">
                                <div class="single-person">
                                    <div class="person-image">
                                        <img src="/img/mrrakesh.png" alt="" />
                                    </div>
                                    <div class="person-info">
                                        <h3 class="full-name">Mr. RAKESH KUMAR SONI </h3>
                                        <span class="speciality"> Other Team Member</span>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- / End Single Person --> */}
                        </div>
                    </div>
                </section>

                <section class="section-services">
                    <div class="container">
                        <div class="row justify-content-center text-center">
                            <div class="col-md-10 col-lg-8">
                                <div class="header-section">
                                    <h2 class="title"> Our Values  </h2>
                                    <p class="description">Our core value is to take action on every word we have promised.</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            {/* <!-- Single Service --> */}
                            <div class="col-md-6 col-lg-4">
                                <div class="single-service">
                                    <div class="content">
                                        <span class="icon">
                                            <i class="icofont icofont-check"></i>
                                        </span>
                                        <h3 class="title">    We are dedicated to grow while allowing everyone to grow with us.</h3>
                                    </div>
                                    <span class="circle-before"></span>
                                </div>
                            </div>
                            {/* <!-- / End Single Service --> */}
                            {/* <!-- Single Service --> */}
                            <div class="col-md-6 col-lg-4">
                                <div class="single-service">
                                    <div class="content">
                                        <span class="icon">
                                            <i class="icofont icofont-check"></i>
                                        </span>
                                        <h3 class="title"> We value innovation and the responsibilities it brings.  </h3>
                                    </div>
                                    <span class="circle-before"></span>
                                </div>
                            </div>
                            {/* <!-- / End Single Service --> */}
                            {/* <!-- Single Service --> */}
                            <div class="col-md-6 col-lg-4">
                                <div class="single-service">
                                    <div class="content">
                                        <span class="icon">
                                            <i class="icofont icofont-check"></i>
                                        </span>
                                        <h3 class="title"> We only want to add value to our stakeholder's lives.  </h3>
                                    </div>
                                    <span class="circle-before"></span>
                                </div>
                            </div>
                            {/* <!-- / End Single Service --> */}
                            {/* <!-- Single Service --> */}
                            <div class="col-md-6 col-lg-4">
                                <div class="single-service">
                                    <div class="content">
                                        <span class="icon">
                                            <i class="icofont icofont-check"></i>
                                        </span>
                                        <h3 class="title">  We are here to make a difference. Because the difference is Good.</h3>
                                    </div>
                                    <span class="circle-before"></span>
                                </div>
                            </div>
                            {/* <!-- / End Single Service --> */}
                            {/* <!-- Single Service --> */}
                            <div class="col-md-6 col-lg-4">
                                <div class="single-service">
                                    <div class="content">
                                        <span class="icon">
                                            <i class="icofont icofont-check"></i>
                                        </span>
                                        <h3 class="title"> We value merits and not entitlements.  </h3>
                                    </div>
                                    <span class="circle-before"></span>
                                </div>
                            </div>
                            {/* <!-- / End Single Service --> */}
                        </div>
                    </div>
                </section>
            </main>
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default AboutUs
