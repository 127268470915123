import React from 'react'
import Footer from '../Footer'

const ResponsibleGaming = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Responsible Gaming Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Responsible Gaming   </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">
                <div className="container">


                    <div id="privacypolicy" class=" container">
                        <p class="">We at Zamppa actively promote responsible game play for our users. We believe that gaming is and should be done purely for entertainment – meaning, playing till it doesn’t affect your body, mind or finances in a negative way. We strongly believe that with the right approach to our games, the experience can be highly rewarding. Games at Zamppa not only give you the thrill of winning against players from across the country but can also improve your strategic prowess, decision making, and time-management skills. <br /> <br />
                            We at Zamppa want you to enjoy our games as much as we have enjoyed creating them for you. As a responsible gaming company, we take the following steps for all our users:</p>

                        <ul class="">
                            <li class="">We comply with all applicable law, rules, regulations, and requirements necessary to run a real-money gaming application and structure our games with a view to being compliant.</li>
                            <li class="">We only allow users who are 18+ to join the game and deploy screening and age-gating techniques and/or obtaining declarations from users as to their age.</li>
                            <li class="">Game rules are explained in detail at the start of the game. They are also accessible as a video or in text format all the time within the app</li>
                            <li class="">We provide 24X7 customer care at <a style={{ color: 'blue' }} href="mailto:support@zamppa.com">support@zamppa.com</a></li>
                            <li class="">We offer multiple tournament options to choose from according to one’s risk appetite</li>
                            <p>We understand that gaming has the potential to be addictive, and one can lose track of time and money whilst playing games. But keeping the following DOs and DON’Ts in mind, you can ensure your love for gaming doesn’t change into an addiction:</p>
                        </ul>
                        <h4 class="mt-3">DO'S</h4>

                        <ul class="">
                            <li class="">Play for entertainment alone, not as a source of earnings or remuneration which in any manner impacts your financial independence.</li>
                            <li class="">Decide in advance how much money you will spend playing the game and only play with disposable income (i.e. such amounts of money you can afford to risk losing entirely without in any way jeopardizing your current financial position.</li>
                            <li class="">Decide on the number of hours you will be devoting to the game and stick to it ensuring you do not exceed such number of hours in any circumstances.</li>
                            <li class="">Set deposit limits while playing so that you never deposit more than your disposable income which you can afford to lose.
                            </li>
                            <li class="">Take a break and pause playing for a while if you have lost money continuously multiple times.
                            </li>
                            <li class="">Reach out to our customer care if you think you are addicted to any of our games and would like us to help you with dealing with your game-addiction.
                            </li>
                        </ul>
                        <h4 class="mt-3">DON'TS</h4>

                        <ul class="">
                            <li class="">Don’t play to earn extra income</li>
                            <li class="">Don’t play impulsively</li>
                            <li class="">Don’t play with loaned money</li>
                            <li class="">Don’t play if you’re not in a calm mental state. This affects your game performance ultimately leading you to lose money.</li>
                            <li class="">Don’t chase losses. Since all games on Zamppa are skill-based, take time to hone your skills to play better.</li>
                            <li class="">Don’t forget to keep a check on how much you are spending on the game.</li>
                            <li class="">Don’t play in between work or other engagements so you can play the game with a free mind. This is also important as you don’t want this to interfere with your day-to-day life.</li>
                            <li class="">Don’t harass, threaten or abuse other players on the Platform or engage in any conduct that would be considered as illegal, unethical or defamatory.</li>
                        </ul>
                        <p class=""><b class="">If you need any further information or help, write to us at<a style={{ color: 'blue' }} href="mailto:support@zamppa.com"> support@zamppa.com </a>and we would be happy to help.</b></p>
                    </div>
                </div>
            </section>
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}

            <Footer/>
        </>
    )
}

export default ResponsibleGaming
