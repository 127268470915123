import React from 'react'
import Footer from '../Footer'

const NoBot = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2>   No Bot  </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">  No Bot </li>
                        </ol>
                    </nav>
                </div>
            </div>


            <main>
                <section class="mt-4 pt-4">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">

                                <p>
                                    No Bots Certificate by (Zamppa Games)
                                    Zamppa aims to ensure fair play for each player every time they play with us. Transparency and integrity are the most important part of our value system and are further reflected in our Fairplay Policy.

                                </p>
                                <p>
                                    This certification (Zamppa Games) has evaluated games by Zamppa (CAPTAIN KRAFT TECHNOLOGIES PRIVATE LIMITED), which ensures relevant UKGC standards and does not use any bot during gameplay.
                                </p>
                                <p> <b> Operators: CAPTAIN KRAFT TECHNOLOGIES PRIVATE LIMITED</b></p>
                                <p>   <b>Software provider URL: www.zamppa.com</b>   </p>
                                <p>   Provide certification details to complete this section  </p>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <br />
            </main >

            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default NoBot
