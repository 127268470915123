import React from 'react'
import { Link } from 'react-router-dom';
function openPdf(file) {
    window.open(file, '_blank', 'toolbar=0,location=0,menubar=0');
}

const Footer = () => {
    return (
        <div>
            <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div
                            className="col-md-5"
                            style={{
                                display: "flex",
                                alignItems: "center", // Vertically align the images
                                gap: "50px", // Add spacing between the images
                            }}
                        >
                            <a href="/">
                                <img
                                    src="img/icon/logo.svg"
                                    className="img-fluid"
                                    alt="Logo"
                                // Adjust size if needed
                                />
                            </a>
                            <img
                                src="/img/min.png"
                                alt="Additional"
                                className="img-fluid"
                                style={{
                                    width: "100px",
                                }}

                            />
                        </div>

                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <Link to="/about">About Us</Link>
                                    <Link to="/termsconditions">Terms & Conditions</Link>
                                    <Link to="/privacypolicy">Privacy Policy</Link>
                                    <Link to="/refund-policy">Refund and Cancellation Policy</Link>
                                    <Link to="/no_bot">No Bots Certification</Link>
                                    <Link to="/free_policy">Zamppa Free Policy</Link>
                                    <Link to="/responsible-gaming-policy">Responsible Gaming</Link>
                                    <Link to="/fairplay">Zamppa Fairplay</Link>
                                    <Link to="/tds_policy">TDS Policy</Link>
                                    <Link to="/gst_policy">Zamppa GST Policy</Link>
                                    <Link to="/withdrawal_policy">Zamppa Withdrawal & KYC Policy
                                    </Link>
                                    <Link to="/free_terms">Zamppa Free Terms & Conditions</Link>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <Link to="/ludoClassic">Ludo Classic </Link>
                                    <Link to="/ludoExpress">Ludo Express </Link>
                                    <Link to="/ludoUltra">Ludo Ultra </Link>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <Link to="/how_to_play">How to Play</Link>
                                    <Link to="/rng_certificate">RNG Certificate </Link>
                                    <Link to="/contact">Contact Us</Link>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <p>FOLLOW US ON :</p>
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <br />
                                    <div
                                        className="download-option-btn"
                                        style={{
                                            display: "flex",
                                            gap: "10px", // Space between images
                                            alignItems: "center", // Align images vertically
                                        }}
                                    >
                                        <img
                                            className="img-fluid"
                                            src="/img/z1.png"
                                            alt="Zamppa"
                                            style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                        />
                                        <img
                                            className="img-fluid"
                                            src="/img/z2.png"
                                            alt="Zamppa"
                                            style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                        />
                                        <img
                                            className="img-fluid"
                                            src="/img/z3.png"
                                            alt="Zamppa"
                                            style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                        />
                                        <img
                                            className="img-fluid"
                                            src="/img/z4.png"
                                            alt="Zamppa"
                                            style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                        />
                                        <img
                                            className="img-fluid"
                                            src="/img/z5.png"
                                            alt="Zamppa"
                                            style={{ width: "100px", height: "auto", objectFit: "contain" }}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Footer
