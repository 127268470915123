import React from 'react'
import Footer from '../Footer'

const GstPolicy = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> GST Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> GST Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <main>
                <section class="mt-4 pt-4">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <h1 className='pp_title'>GST DEDUCTION ON</h1>
                                <div style={{ backgroundColor: "#c19859", padding: "10px", borderRadius: "5px", color: "#fff" }}>
                                    <p style={{ margin: 0 }}>
                                        The Government of India has announced that starting 1st October 2023, a 28% Tax (GST) will be applicable on all cash deposits.
                                    </p>
                                </div><br />

                                <h3><strong>What it means:</strong></h3>
                                <hr />
                                <p>GST will only be deducted at the time of adding cash to your account.</p>
                                <p> GST will not be deducted from the entry fee or for the number of games played.</p>

                                <div style={{ backgroundColor: "#c19859", padding: "10px", borderRadius: "5px", color: "#fff" }}>
                                    <p style={{ margin: 0 }}>
                                        <b>Note: The cash amount you add to your account will be inclusive of 28% GST.</b>
                                    </p>
                                </div><br />
                                <p>A player who deposits ₹100 will receive ₹78.12 in their account after a 28% GST deduction of ₹21.88.</p>
                                <div class="orange-card">
                                    <p><strong>HOW WILL IT IMPACT ME?</strong></p>
                                    <img src="/img/gst_policy.png" alt="GST Policy" />
                                    <p><strong>Good News! </strong>ZAMPPA LUDO will give you a 100% cashback reward for the GST paid by you.</p>
                                    <p>Let’s understand this with an example:</p>
                                    <p>If you deposit ₹100, you will get the full amount of ₹100 in your account.</p>
                                </div>
                                <div style={{ backgroundColor: "#c19859", padding: "10px", borderRadius: "5px", color: "#fff", textAlign: "center" }}>
                                    <h4 style={{ margin: 0 }}>
                                        <b>Player Action</b>
                                    </h4>
                                </div><br />
                                <table
                                    border="1"
                                    cellspacing="0"
                                    cellpadding="10"
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        textAlign: "left",
                                    }}
                                >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td> Deposit Amount</td>
                                            <td>₹ 100</td>
                                        </tr>
                                        <tr>
                                            <td> 28% GST Paid to Govt.
                                                Included in ₹100 (28% of 78.12)</td>
                                            <td style={{ color: "red" }}> (-) ₹ 21.88</td>
                                        </tr>
                                        <tr>
                                            <td>Amount Credited to Deposit Balance</td>
                                            <td>₹ 78.12</td>
                                        </tr>
                                        <tr>
                                            <td>100% GST Cashback & Rewards</td>
                                            <td style={{ color: "green" }}> (+) ₹ 21.88</td>
                                        </tr>
                                        <tr>
                                            <td>Player will get</td>
                                            <td> =  ₹ 100</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <br />
                                <p><strong>What is the recent GST change for online gaming?</strong></p>
                                <p>The Indian government has imposed a 28% GST (Goods and Services Tax) on deposits made for online gaming. This tax is applicable to the face value.</p>
                                <p><strong>What is "face value"?</strong></p>
                                <p>"Face value" refers to the amount deducted from your account for a deposit into your game account. The GST will apply to this amount only. No GST will apply to any bonus that you get for a deposit.</p>
                                <p><strong>When will the new GST rate be effective?</strong></p>
                                <p>The new GST rate of 28% will be effective from 1st October 2023.</p>
                                <p><strong>Will I have to pay GST on my past transactions as well (made before 1st October)?</strong></p>
                                <p>Previously, Net Winnings were calculated as No, this revised GST rate will only apply to deposits made on or after 1st October 2023. Any deposits made before 1st October 2023 will not be subject to GST.</p>
                                <p><strong>Will I have to pay GST on both free and practice games?Will I have to pay GST on both free and practice games?</strong></p>
                                <p>No. GST is deducted only at the time of making a cash deposit. It has no impact on the types of games played. If you are a free player, you will not have to pay any GST for playing free games.</p>
                                <p><strong>Is this GST rate fixed for all amounts?</strong></p>
                                <p>Yes, the GST rate is fixed at a flat 28% on the face value.</p>
                                <p><strong>How will I know if the GST collected from me has been paid to the government?</strong></p>
                                <p>An invoice is issued for every deposit transaction. You can access all your invoices from the profile section.</p>
                                <p><strong>Will GST be deducted from the entry fee or the winning amount as well?</strong></p>
                                <p>No. GST is deducted only once at the time of making a cash deposit. If you deposit ₹100 into your account, GST will be deducted only from ₹100. It will have no impact on the entry fee paid, number of games played, or the amount won.</p>
                            </div>
                        </div>
                    </div>
                </section >
                <br />
                <br />
            </main >
            {/* Footer area */}
            < Footer />
        </>
    )
}

export default GstPolicy;
