import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from '../ui/pages/LandingPage'
import AuthHeader from '../customComponent/AuthHeader';
import PrivacyPolicy from '../ui/pages/PrivacyPolicy';
import TermsConditions from '../ui/pages/TermsConditions';
import FairPlay from '../ui/pages/FairPlay';
import ResponsibleGaming from '../ui/pages/ResponsibleGaming';
import RefundPolicy from '../ui/pages/RefundPolicy';
import ContactUs from '../ui/pages/ContactUs';
import LegalPolicy from '../ui/pages/LegalPolicy';
import AboutUs from '../ui/pages/AboutUs';
import HowToPlay from '../ui/pages/HowToPlay';
import NoBot from '../ui/pages/NoBot';
import RNGCertificate from '../ui/pages/RNGCertificate';
import FreeTermsConditions from '../ui/pages/FreeTermsConditions';
import FreePrivacyPolicy from '../ui/pages/FreePrivacyPolicy';
import LudoClassic from '../ui/pages/LudoClassic';
import LudoExpress from '../ui/pages/LudoExpress';
import LudoUltra from '../ui/pages/LudoUltra';
import WithdrawalPolicy from '../ui/pages/WithdrawalPolicy';
import TdsPolicy from '../ui/pages/TdsPolicy';
import GstPolicy from '../ui/pages/GSTPolicy';

const Routing = () => {



    return (
        <Router>
            <AuthHeader />
            <Routes>
                <Route exact path="/" element={<LandingPage />}></Route>
                <Route exact path="/privacypolicy" element={<PrivacyPolicy />}></Route>
                <Route exact path="/termsconditions" element={<TermsConditions />}></Route>
                <Route exact path="/fairplay" element={<FairPlay />}></Route>
                <Route exact path="/about" element={<AboutUs />}></Route>
                <Route exact path="/how_to_play" element={<HowToPlay />}></Route>
                <Route exact path="/no_bot" element={<NoBot />}></Route>
                <Route exact path="/rng_certificate" element={<RNGCertificate />}></Route>
                <Route exact path="/responsible-gaming-policy" element={<ResponsibleGaming />}></Route>
                {/* <Route exact path="/anti-money-laundering-policy" element={<AntiMoneyLaundering />}></Route> */}
                <Route exact path="/refund-policy" element={<RefundPolicy />}></Route>
                <Route exact path="/contact" element={<ContactUs />}></Route>
                <Route exact path="/legality" element={<LegalPolicy />}></Route>
                <Route exact path="/free_terms" element={<FreeTermsConditions />}></Route>
                <Route exact path="/ludoClassic" element={<LudoClassic />}></Route>
                <Route exact path="/ludoExpress" element={<LudoExpress />}></Route>
                <Route exact path="/ludoUltra" element={<LudoUltra />}></Route>
                <Route exact path="/free_policy" element={<FreePrivacyPolicy />}></Route>
                <Route exact path="/withdrawal_policy" element={<WithdrawalPolicy />}></Route>
                <Route exact path="/tds_policy" element={<TdsPolicy />}></Route>
                <Route exact path="/gst_policy" element={<GstPolicy />}></Route>

            </Routes>
        </Router>

    )
}

export default Routing
