import React from 'react'
import { useRef } from 'react';


const AuthHeader = () => {


  const ref = useRef(null);

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };



  return (
    <>
      <header>
        <nav id="header-top" className="navbar navbar-expand-md">
          <div className="container">
            <div className="navbar-header">
              <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navigation"
                aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <a className="navbar-brand" href="/"><img src="img/icon/logo.svg" alt="logo" className="img-responsive" /></a>
            </div>
            <div className="collapse navbar-collapse" id="navigation">
              <ul className="nav nav-pills navbar-nav navbar-right">
                <li className="nav-item">
                  <a className="nav-link active" aria-current="page" href="/" >Home</a>

                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#features">Features</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#games">Our Games</a>
                </li>
                <li className="nav-item"><a className="nav-link" href="/#faq">FAQs</a></li>

                {/* <!-- <li className="nav-item"><a className="nav-link" href="#contact">Contact us</a></li>  --> */}
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}

export default AuthHeader
