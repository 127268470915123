import React from 'react'
import Footer from '../Footer'

const FairPlay = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Zamppa FairPlay </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> FairPlay </li>
                        </ol>
                    </nav>
                </div>
            </div>


            <main>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>How Zamppa Maintains Fairplay?</h2>
                    </div>
                </section>
                <section class="mt-4 pt-4">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                We at Zamppa want to ensure a fair game for you every time you play with us. We highly value transparency, fairness, and integrity, which is further reflected in our Fairplay Policy and in all aspects of our online gaming services.
                                We retain the authority to exercise appropriate measures, as determined solely by us, to uphold this Policy with respect to all games conducted on the Platform. Therefore, we strongly advise Users to carefully review this Policy prior to participating in any game on the Platform.
                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>1. TRANSPARENCY</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <ol type="a">
                                    <li><strong> &nbsp;Equal Opportunity </strong>
                                        <p>Our games ensure equal opportunities for all our users. The game rules and regulations apply to every player equally. We take every measure possible to avoid hacking or cheating on our platform. Deadlines are put in place for joining each tournament to ensure every user has an equal opportunity at the game.
                                            All rules, odds, and game mechanics are made accessible to all users, ensuring transparency in the gaming process.</p>
                                    </li>
                                    <li><strong> &nbsp;Playing Winnings</strong>
                                        <p>
                                            It is important that our users know upfront what potential winnings they could take home if they win, when they sign up for a game. We want your experience on the games to be seamless and have taken the following steps to ensure you enter a tournament with full confidence:
                                            <ul>
                                                <li>The scoring system used for calculating points is clearly illustrated and displayed for every new user being onboarded on our platform.</li>
                                                <li>The applicable play money for winners of each game is clearly mentioned. Any winnings/payouts are promptly processed and credited to the User’s account as per our Terms of Use and other applicable Zamppa policies.</li>
                                                <li>Any cancellation fee, or consequences of leaving the game mid-way or engaging in unfair gaming practices is also prescribed in this Policy and the Terms and Conditions.</li>
                                            </ul>
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>2. Trust</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <ul>
                                    <li><strong> &nbsp; a) Genuine, Verified Users</strong>
                                        <p>We strive to ensure that we only have genuine users playing Zamppa games. There are  no robots allowed on the Platform (As defined in the Terms and Conditions). To facilitate this, we have put in place appropriate policies and protocols to check any violation of this Policy. Before withdrawing any winnings, depending on the amount and/or frequency of the withdrawal, a user may be required to verify the user’s identity, and account – e.g.: copies of PAN card & bank account and other required details. These details and documents are collected and verified as prescribed by respective law or by regulatory authorities either by us directly, or via a third-party service provider.
                                            Captain Kraft employees are not allowed to participate in the contests hosted on the application for the users.</p>
                                    </li>
                                    <li> <strong>&nbsp; b) Safety & Security</strong>
                                        <p>Payments: We at Zamppa have integrated a safe & secure withdrawal process to ensure the smooth withdrawal of money for our users. We work with trusted third-party payment gateways with 3-D secure authentication that guarantees the safety and security of all financial transactions. We do not store your financial/payment instruments like credit cards and debit cards, UPI handles, and wallet details with us and these are stored only with authorized technology service providers and entities permitted to store such data in accordance with applicable laws and regulatory directives.
                                            We periodically engage independent third-party auditors as per our internal policies to assess the fairness and integrity of our gaming systems.</p>
                                    </li>
                                    <li><strong>&nbsp; c) Communication</strong>

                                        <p>Keeping in mind the importance of being accessible to our users, Zamppa has built processes where users can reach out and raise issues on our Helpdesk section. Resolutions to issues raised are provided within a short span and can be easily tracked by the users.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>3. RESPONSIBILITY</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <ul>
                                    <li><strong> &nbsp; a) Protecting User Information</strong>
                                        <p>Protecting User Information Zamppa deploys industry leading data security protocols and measures to protect personal user information like PAN card details and bank account details. Any personal information shared with us is only used for user verification and is not disclosed to any outside parties except for in special cases where it is required to use their services for verification, or unless required to be disclosed under compulsion of law, or by law enforcement, regulatory or judicial authorities as provided in our Privacy Policy.</p>
                                    </li>
                                    <li> <strong>&nbsp; b) Responsible Play</strong>
                                        <p>Users below 18 years of age, unsound mind and incompetent to enter into contract are not permitted to access or use the games provided on the Zamppa Platform. We do not allow users from certain restricted States to play our games, as per the prevalent law in such states. The users are liable to ensure compliance with the applicable laws in their states and Zamppa is not liable for any breach of such laws by the User. We don’t allow our users to participate in more than one tournament at the same time. You can read more about our Responsible Gaming policies in the Responsible Gaming</p>
                                    </li>
                                    <li><strong>&nbsp; c) Compliance with Law</strong>

                                        <p>Zamppa is a skill-based platform that can help gamers improve their strategic prowess, decision making, and time-management skills. Our games are compliant with all the applicable laws and regulations.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>4. FAIRPLAY AND PREVENTION OF COLLUSION AND FRAUD POLICY</h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <ul className='d-block'>
                                    <li>
                                        Zamppa is committed to promoting fair play on the platform. We might track gameplay to enforce our terms and policies, and appropriate action may be taken against any player(s) caught indulging in any form of abuse of policies, and/or collusion. By agreeing to the Terms and Conditions of this Platform, you agree not to indulge in any unfair game practice that might provide an undue advantage to you or any other player.

                                    </li>
                                    <li>
                                        Zamppa has automated detection systems to identify fraudulent behaviour in games.
                                    </li>
                                    <li>
                                        Any player found to be using duplicate accounts or using another person’s account for unfair activities, for example, alleged or found adding money or withdrawing from/to an account suspected of being fraudulent, is liable to face legal consequences including, but not limited to, withholding any withdrawal or use of the player’s balance, and locking of the user’s account.
                                    </li><br /><br />

                                    <li> I. Actions utilizing unfair methods to manipulate the outcome of a game on the Platform, regardless of whether these actions provide an advantage to the user employing such methods.</li>
                                    <li> II. Actions that Zamppa, in its sole discretion, deems to be fraudulent, illegal, or unfair.</li>
                                    <li> III. Actions solely intended for the transfer of funds from one party to another or between distinct sources, thus constituting a form of financial transfer.</li>
                                    <li> IV. Actions involving collusion between two or more players to achieve a predetermined outcome during a game, effectively constituting syndicate play.</li>
                                    <li> V. Actions falling within the scope of prohibited conduct, as defined in Zamppa’s policies.</li><br /><br />
                                    <p><li>We reserve the right to take any necessary action, as we deem fit, against users who indulge in unfair means and practices. The action could include without limitation penalties, temporary account suspension, withdrawal blocking, investigations, permanent account deactivation, forfeiture of winnings/deposit(s), levy violation fees, freezing funds of colluding users etc. If you suspect any player colluding in any game or involved in unfair practices, you can write to us at <a style={{ color: 'blue' }} href="mailto:support@zamppa.com">support@zamppa.com.</a></li></p>
                                    <p><li>Please note that we can terminate or suspend your Account and all associated accounts, institute fraud prevention measures such as access restrictions, transaction velocity restrictions etc. without prior notice to prevent unauthorised use of our Accounts. You must cooperate with us in any investigation and use any fraud prevention measures we prescribe, and you agree that we will not be liable to you or any third party for any block, suspension, cancellations, or termination of your use of an Account owing to such measures.</li></p>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </main >
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default FairPlay
