import React from 'react'
import Footer from '../Footer'

const TermsConditions = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Terms & Conditions</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Terms & Conditions</li>
                        </ol>
                    </nav>
                </div>
            </div>
            <section className="common_content_box">
                <div className="container">
                    <div class="">

                        <p class="">These Terms and Conditions, along with privacy policy or other terms (“Terms”) constitute a binding agreement by and between CAPTAIN KRAFT TECHNOLOGIES PRIVATE LIMITED, ( “Website Owner” or “we” or “us” or “our”) and you (“you” or “your”) and relate to your use of our website, goods (as applicable) or services (as applicable) (collectively, “Services”).</p>
                        <p class="">By using our website and availing the Services, you agree that you have read and accepted these Terms (including the Privacy Policy). We reserve the right to modify these Terms at any time and without assigning any reason. It is your responsibility to periodically review these Terms to stay informed of updates..&nbsp;</p>

                        <p class="">The use of this website or availing of our Services is subject to the following terms of use:</p>
                        <ul class="">
                            <li class="">To access and use the Services, you agree to provide true, accurate and complete information to us during and after registration, and you shall be responsible for all acts done through the use of your registered account. &nbsp; &nbsp;</li>

                            <li class="">Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials offered on this website or through the Services, for any specific purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law. &nbsp; &nbsp;</li>

                            <li class="">This Platform may not be used by residents of Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, Tamil Nadu and Telangana. Further, there may be certain games that may be restricted in some additional states. To understand more about who may use this platform please refer to Our Eligibility section &nbsp; &nbsp;</li>

                            <li class="">Your use of our Services and the websiteis solely at your own risk and discretion.. You are required to independently assess and ensure that the Services meet your requirements. &nbsp; &nbsp;</li>

                            <li class="">The contents of the Website and the Services are proprietary to Us and you will not have any authority to claim any intellectual property rights, title, or interest in its contents. &nbsp; &nbsp;</li>

                            <li class="">You acknowledge that unauthorized use of the Website or the Services may lead to action against you as per these Terms or applicable laws. &nbsp; &nbsp;</li>

                            <li class="">You agree to pay us the charges associated with availing the Services.&nbsp; &nbsp;</li>

                            <li class="">You agree not to use the website and/ or Services for any purpose that is unlawful, illegal or forbidden by these Terms, or Indian or local laws that might apply to you. &nbsp; &nbsp;</li>

                            <li class="">You agree and acknowledge that website and the Services may contain links to other third party websites. On accessing these links, you will be governed by the terms of use, privacy policy and such other policies of such third party websites. &nbsp; &nbsp;</li>

                            <li class="">You understand that upon initiating a transaction for availing the Services you are entering into a legally binding and enforceable contract with the us for the Services. &nbsp; &nbsp;</li>

                            <li class="">You shall be entitled to claim a refund of the payment made by you in case we are not able to provide the Service. The timelines for such return and refund will be according to the specific Service you have availed or within the time period provided in our policies (as applicable). In case you do not raise a refund claim within the stipulated time, than this would make you ineligible for a refund.&nbsp;</li>

                            <li class="">Notwithstanding anything contained in these Terms, the parties shall not be liable for any failure to perform an obligation under these Terms if performance is prevented or delayed by a force majeure event. &nbsp; &nbsp;</li>

                            <li class="">These Terms and any dispute or claim relating to it, or its enforceability, shall be governed by and construed in accordance with the laws of India. &nbsp; &nbsp;</li>

                            <li class="">All disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts in INDORE, Madhya Pradesh &nbsp; &nbsp;</li>

                            <li class="">All concerns or communications relating to these Terms must be communicated to us using the contact information provided on this website. &nbsp; &nbsp;</li>
                        </ul>
                    </div><br />
                    <div className='text-center'>
                        <h5>Developed and Designed by <a style={{ color: 'blue' }} href="https://appinop.com/" target='_blank'>Appinop Technologies</a> </h5>
                        <h5>Contact No. : +91 978 829 5850</h5>
                    </div>
                </div>
            </section>
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default TermsConditions
