import React from 'react'
import Footer from '../Footer'

const LegalPolicy = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> Zamppa Legal Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> Legal Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>


            <main>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Legal  </h2></div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">

                                Most definitely–Yes. Playing Ludo online at Zamppa is legal In India provided you are not playing from Assam, Telangana, Andhra Pradesh, Sikkim, Nagaland, Meghalaya, & Odisha. Ludo has been declared as a game of skill process as stated by the Supreme Court of India in 1968. Ludo has been held to be a “game of mere skill” in a plethora of judgments by the Supreme Court and high courts of India. In 1996, Supreme Court of India held that: A game of skill, on the other hand - although the element of chance necessarily cannot be entirely eliminated, it is one in which success depends principally upon the superior knowledge, training, attention, experience and adroitness of the player. Golf, chess and even Ludo are considered to be games of skill. The Courts have reasoned that there are few games, if any, which consist purely of chance or skill, and as such a game of chance is one in which the element of chance predominates over the element of skill, and a game of skill is one in which the element of skill predominates over the element of chance. It is the dominant element --"skill" or "chance" -- which determines the character of the game.

                            </div>
                        </div>
                    </div>
                </section>
                <section class=" container">
                    <div class=" undefined">
                        <h2 className='pp_title'>Does playing Ludo online categorise as 'Gambling'?  </h2>
                        <p>Short answer – No. Read on for the long answer.</p>
                    </div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">   The Supreme Court in two landmark cases in 1957 has held that competitions which involve substantial skill are not gambling activities; Such competitions are business activities, the protection of which is guaranteed under Article 19(1) (g) of the Constitution of India. It was clearly laid down by the Supreme Court that a “game of skill” is protected under Article 19(1)(g) of the Constitution of India and, therefore, profit or gain from any activity based on skill cannot be treated as gaming or gambling.</div>
                        </div>
                    </div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">However, in Assam, Telangana, Andhra Pradesh, Sikkim, Nagaland, Meghalaya, & Odisha players are not allowed to play for cash. Zamppa does not allow players from these states to play for real money. But, they can still play our practice games for free.</div>
                        </div>
                    </div>
                </section>
                <section class="">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">or any queries you can write to support@Zamppa.com . You can Refer to Our Terms of service for more details.</div>
                        </div>
                    </div>
                </section>

            </main>
            <br />
            <br />
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default LegalPolicy
