import React from 'react'
import Footer from '../Footer'

const TdsPolicy = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> TDS Policy </h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page"> TDS Policy </li>
                        </ol>
                    </nav>
                </div>
            </div>
            <main>
                <section class="mt-4 pt-4">
                    <div class=" container">
                        <div class=" disc-para">
                            <div class=" regular--text trim-Regular m-0">
                                <img src="/img/tdspolicy.png" alt="" />

                                <h2 className='pp_title'>As per the Finance Act 2023-24, 30% TDS will be deducted from all positive net winnings in</h2>
                                <h3>What’s New?</h3>
                                <ul>
                                    <li> &nbsp; &nbsp; &nbsp; &nbsp; Pay TDS only on net winnings.</li>
                                    <li>&nbsp; &nbsp; &nbsp; &nbsp; TDS will be deducted from the net winnings included in the withdrawal amount at the time of withdrawal and/or at the end of the financial year (FY).</li>
                                    <p><strong>Note:  </strong>This policy is subject to change depending on any further directive issued by the Government of India.</p>
                                </ul>
                                <h3>What does it mean for players?</h3>
                                <p>Players have to pay 30% TDS only on positive net winnings.</p>
                                <p> Here is the formula to calculate net winnings:</p>
                                <img src="/img/tdsPolicy.png" alt="" />
                                <h3>Understanding Key Terms</h3>
                                <p> &nbsp; &nbsp; &nbsp; &nbsp;Withdrawals: Current Withdrawal + previous withdrawals made in the current FY</p>
                                <p>&nbsp; &nbsp; &nbsp; &nbsp; Deposits: Total amount deposited into the account by the player in the current FY</p>
                                <p>&nbsp; &nbsp; &nbsp; &nbsp;FY Opening Balance: Total account balance (deposit + withdrawable) on 1st April, i.e. at the start of the
                                    current FY.</p>
                                <p><strong>*</strong> Financial Year (FY) = 1st April to 31st March</p>
                                <p><strong>Scenario 1: A player places the 1st withdrawal request for ₹10,000 in the financial year.</strong></p>
                                <table border="1" cellspacing="0" cellpadding="10" style={{
                                    width: " 100%", borderCollapse: "collapse", textAlign: "left"
                                }}>
                                    <thead>
                                        < tr style={{ backgroundColor: " #f2f2f2" }}>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Amount the player wants to withdraw (A)</td>
                                            <td>₹10,000</td>
                                        </tr>
                                        <tr>
                                            <td>Deposit made by player (B)</td>
                                            <td>₹3,000</td>
                                        </tr>
                                        <tr>
                                            <td>FY Opening Balance, on 1st April 2023 (C)</td>
                                            <td>₹5,000</td>
                                        </tr>
                                        <tr>
                                            <td>Net Winnings
                                                ( A − B − C)</td>
                                            <td>₹2,000</td>
                                        </tr>
                                        <tr>
                                            <td>30% TDS on Net Winnings</td>
                                            <td>₹600</td>
                                        </tr>
                                        <tr >
                                            <td>Player will get</td>
                                            <td>₹9,400</td>
                                        </tr>
                                    </tbody>
                                </table><br />
                                <p><strong>Scenario 2: A player places the 1st withdrawal request for ₹5,000 in the financial year.</strong></p>
                                <table
                                    border="1"
                                    cellspacing="0"
                                    cellpadding="10"
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        textAlign: "left",
                                    }}
                                >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Amount the player wants to withdraw (A)</td>
                                            <td>₹5,000</td>
                                        </tr>
                                        <tr>
                                            <td>Deposit made by player (B)</td>
                                            <td>₹3,000</td>
                                        </tr>
                                        <tr>
                                            <td>FY Opening Balance, on 1st April 2023 (C)</td>
                                            <td>₹5,000</td>
                                        </tr>
                                        <tr>
                                            <td>Net Winnings (A − B − C)</td>
                                            <td>-₹3,000</td>
                                        </tr>
                                        <tr>
                                            <td>30% TDS on Net Winnings</td>
                                            <td>₹0</td>
                                        </tr>
                                        <tr>
                                            <td>Player will get</td>
                                            <td>₹5,000</td>
                                        </tr>
                                    </tbody>
                                </table><br />
                                <p>No TDS will be deducted when there are no positive net winnings.</p>
                                <p><strong>What happens when a player makes multiple withdrawals and deposits in a financial year?</strong></p>
                                <p>Let us understand this with the help of a few examples of what happens when a player makes 2 or more withdrawals in a financial year.</p>

                                <p><strong>Scenario 1: When Net winnings increase in the financial year</strong></p>
                                <p>FY Opening Balance: ₹5,000</p>
                                <p>The player deposits ₹3,000 on 5th April 2023.</p>
                                <p>1st withdrawal made on 20th May 2023 is as follows:</p>
                                <table
                                    border="1"
                                    cellspacing="0"
                                    cellpadding="10"
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        textAlign: "left",
                                    }}
                                >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Withdrawal 1 (A)</td>
                                            <td>₹10,000</td>
                                        </tr>
                                        <tr>
                                            <td>Deposit made (B)</td>
                                            <td>₹3,000</td>
                                        </tr>
                                        <tr>
                                            <td>FY Opening Balance (C)</td>
                                            <td>₹5,000</td>
                                        </tr>
                                        <tr>
                                            <td>Net Winnings (A − B − C)</td>
                                            <td>₹2,000</td>
                                        </tr>
                                        <tr>
                                            <td>30% TDS on Net Winnings</td>
                                            <td>₹600</td>
                                        </tr>
                                        <tr>
                                            <td>Player Received</td>
                                            <td>₹9,400</td>
                                        </tr>
                                    </tbody>
                                </table><br />
                                <p>The player deposits ₹7,000 on 10th June 2024.</p>
                                <p>The player wants to make a 2nd withdrawal of ₹4,000 on 5th July 2024.</p>
                                <table
                                    border="1"
                                    cellspacing="0"
                                    cellpadding="10"
                                    style={{
                                        width: "100%",
                                        borderCollapse: "collapse",
                                        textAlign: "left",
                                    }}
                                >
                                    <thead>
                                        <tr style={{ backgroundColor: "#f2f2f2" }}>
                                            <th>Description</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Total Withdrawal (A) (₹10,000 + ₹4,000)</td>
                                            <td>₹14,000</td>
                                        </tr>
                                        <tr>
                                            <td>Total Deposit (B) (₹3,000 + ₹7,000)</td>
                                            <td>₹10,000</td>
                                        </tr>
                                        <tr>
                                            <td>FY Opening Balance (C)</td>
                                            <td>₹5,000</td>
                                        </tr>
                                        <tr>
                                            <td>Net Winnings (A − B − C)</td>
                                            <td>-₹1,000</td>
                                        </tr>
                                        <tr>
                                            <td>30% TDS on Net Winnings</td>
                                            <td>₹0</td>
                                        </tr>
                                        <tr>
                                            <td>TDS Deduction on 2nd Withdrawal</td>
                                            <td>₹0</td>
                                        </tr>
                                        <tr>
                                            <td>Player will get</td>
                                            <td>₹4,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>Since the player’s Net Winnings have decreased, no TDS will be deducted from this withdrawal.</p>
                                <p><strong>Note -</strong> This is a case of excess TDS paid on previous withdrawal.</p>
                                <p>In the above example,</p>
                                <p>TDS paid on 1st withdrawal = ₹600</p>
                                <p>TDS liability on 2nd Withdrawal (based on current Net Winnings) = ₹0</p>
                                <p>Excess TDS paid = ₹600</p>
                                <p><strong>Note -</strong> There are 2 ways to claim back any excess TDS paid:</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Players can claim a refund from the Income Tax Department while filing ITR.</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;Players can make tax-free withdrawals as long as their tax liability does not exceed the amount of excess tax they have already paid.</p>
                                <p>In the case of the above example,</p>
                                <p>The player can withdraw up to ₹2000 tax-free, i.e. without paying any TDS. (30% TDS on ₹2000 = ₹600)</p>
                                <p><strong>Financial Year-End Settlement</strong></p>
                                <p>Suppose a player has some balance (deposit balance + withdrawable balance) in the account at the end of the financial year, i.e. 31st March. In that case, TDS will be deducted from that amount by considering the whole amount as a withdrawal for that financial year.</p>
                                <p>A player’s TDS liability will not be carried forward to the next financial year. The closing balance in a player’s account after the TDS settlement will become the Opening Balance for the next financial year.</p>
                                <p>Total Withdrawal = Withdrawals made in the FY + Account balance on 31st March.</p>
                                <p>Net Winnings = Total Withdrawal − Total Deposit − FY Opening Balance
                                    Net Winnings from which TDS has already been deducted will not be considered for the final settlement.</p>
                                <p>Let us understand this with a few examples:</p>
                                <p><strong>Case 1. No Withdrawals made in the financial year</strong>    </p>
                                <p><strong>Case 2 - Multiple withdrawals and deposits made during the financial year.</strong></p>
                                <p><strong>Case 3 - When Net Winnings on 31st March are 0.</strong></p>
                                <p><strong>Case 4. When Net Winnings on 31st March are negative.</strong></p>
                                <p><strong>Prizes other than Cash</strong></p>
                                <p>When a player wins non-cash prizes of real value (white goods) like cars, motorbikes, gadgets, etc, the player will have to pay 30% TDS to Zamppa Ludo before receiving the prize.</p>
                                <p>Zamppa Ludo will remit/pay the TDS to the government on the player’s behalf and issue the player a TDS certificate.</p>
                                <p><strong>Things to Remember :</strong></p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp; PAN verification is mandatory for making any withdrawal.</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;  Players will get a TDS certificate for tax filing after the quarter ends.</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp; Players can check the Tax Sheet in the ”Profile” section of www.zamppa.com.</p>
                                <h3>Frequently Asked Questions</h3>
                                <h3>What is TDS?</h3>
                                <p>TDS means tax deducted at source.</p>
                                <p>According to income tax rules, a person/organization that is liable to make a payment of specified nature to any other person shall deduct tax at source and remit/pay the amount into the account of the Government of India. A TDS certificate is issued by the person/organization that deducts the tax at source.</p>
                                <h3>What are the applicable TDS rates?</h3>
                                <p>TDS is deducted at the rate of 30% from net winnings.</p>
                                <h3>Has there been a change to the calculation of Net Winnings?</h3>
                                <p>Yes, a new rule for Net Winnings calculation was introduced by the Government of India in the financial year 2023-24, which is still applicable.</p>
                                <h3>What changed in the Net Winnings calculation with the introduction of the new rule?</h3>
                                <p>Previously, Net Winnings were calculated as Total Winnings − Total Losses. Now, Net Winnings are calculated as Total Withdrawals − Total Deposits − Opening Balance.</p>
                                <h3>Why do I have to pay TDS if my income threshold as per the new/old tax regime is not getting crossed?</h3>
                                <p>The tax liability on game winnings is calculated at the rate of 30% irrespective of the player’s income threshold. Your tax liability for the rest of your income is calculated as per the income tax slabs and deductions. Please consult your tax advisor for more information.</p>
                                <h3>I have already provided my Aadhaar. Why is PAN needed?</h3>
                                <p>PAN is needed to issue a TDS certificate, which can be used for filing ITR and claiming a refund (if any).</p>
                                <h3>What if I don’t have a PAN?</h3>
                                <p>You will be unable to withdraw money if your PAN is not linked to your Junglee Ludo account.</p>
                                <h3>Where do I get the TDS certificate from?</h3>
                                <p>You will receive your TDS certificate from Junglee Ludo 45 days after the end of a financial quarter.</p>
                                <h3>I see different amounts getting deducted from each withdrawal. What is the logic for TDS calculation?</h3>
                                <p>The TDS is deducted based on 5 parameters: Net winnings, total withdrawals, total deposits, opening balance at the start of the financial year, and TDS already paid on previous withdrawals. Depending on your Net Winnings at the time of withdrawal, 30% TDS is deducted from the withdrawal amount. You can always check the details in the Tax Sheet in the “Withdrawals” section.</p>
                                <h3>How can I check if the TDS deducted from my winnings has been paid to the government?</h3>
                                <p>You will receive your TDS certificate from Junglee Ludo 45 days after the end of a financial quarter. This TDS certificate will show the tax paid to the government on your behalf.</p>
                                <h3>What will happen to the excess tax paid?</h3>
                                <p>You can claim back any excess TDS paid by filing an ITR return. Junglee Ludo will provide you with a TDS certificate within 45 days of the quarter's end for the purposes of filing ITR.</p>
                                <h3>Will I get a refund from Junglee Ludo?</h3>
                                <p>No. Any excess tax paid will be refunded to the player by the Government of India after the player files an ITR. Junglee Ludo has no legal authority or liability to refund any TDS paid.</p>
                                <h3>What will happen to the balance in my Junglee Ludo account at the end of the financial year (31st March)?</h3>
                                <p>Your account balance as on 31st March will be added to your Total Withdrawal to calculate your Net Winnings for the financial year. There can be any one of the following 2 scenarios:</p>
                                <p> &nbsp;&nbsp;&nbsp;&nbsp;1. Net Winnings: Net Winnings ₹0
                                    30% TDS will be deducted from your Net Winnings. The remaining account balance will be carried forward as the opening balance for the next financial year and will be tax-free.</p>
                                <p>&nbsp;&nbsp;&nbsp;&nbsp;2. Negative Net Winnings: Net Winnings ≤ ₹0
                                    No TDS will be deducted. The entire account balance will be carried forward as the opening balance for the next financial year and will be tax-free.</p>
                            </div>
                        </div>
                    </div>
                </section >
                <br />
                <br />
            </main >
            {/* Footer area */}
            < Footer />
        </>
    )
}

export default TdsPolicy;
