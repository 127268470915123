import React from 'react'
import Footer from '../Footer'

const FreeTermsConditions = () => {

    return (
        <>
            {/* <!-- hero area start --> */}
            <div className="hero-area page_banner" id="slider-area">
                <div className="container">
                    <h2> ZAMPPA FREE APP TERMS AND CONDITIONS</h2>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/" className="text-warning" >Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">FREE TERMS AND CONDITIONS</li>
                        </ol>
                    </nav>
                </div>
            </div>


            <section class="mt-4 pt-4">
                <div class=" container">
                    <div class=" disc-para">
                        <div class=" regular--text trim-Regular m-0">

                            <h3>A. GENERAL</h3>

                            <ul>
                                <p>
                                    1. These are the Company Terms and Conditions of Use <strong>(‘Terms’)</strong> which form a legal and binding agreement amongst Captain Kraft Technologies Private Limited (referred to as the <strong>‘Company’, ‘Zamppa’, ‘Us’, ‘We’ or ‘Our’</strong>) and any person <strong>(‘You’/ ‘Your’/ ‘User’)</strong> accessing:
                                </p>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp; <strong>a.</strong> Our website – www.zamppa.com <strong>(‘Website’)</strong> and the Zamppa mobile application <strong>(‘App’)</strong> including any sub-domains of the Website, or related top-level domains including www.zamppa.in, mobile sites, apps, APIs, widgets (hereinafter collectively referred as <strong>‘the Platform’)</strong>, and/or;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp; <strong>b.</strong> any data, information, text, graphics, video, sound, pictures, and any other materials appearing, sent, uploaded, communicated, transmitted, or otherwise made available via the Platform (jointly referred to as the <strong>‘Content’</strong> ) for participating in various contests and games hosted on the Platform  <strong>(‘ Services‘ / ‘Games’)</strong>.</li> <br /> <br />
                            </ul>
                            <p>2. Such User (as described above) shall be bound by these Terms, Privacy Policy ( <strong>Privacy Policy</strong> ) and all other rules, regulations, and terms of use (including Privacy Policy) referred to herein or provided by Company in relation to any Company Services</p>
                            <p>3. This document is an electronic record in terms of the Information Technology Act, 2000 and rules there under as applicable and various other statutes. This electronic record is generated by a computer system and does not require any physical or digital signatures.</p>
                            <p>4. We reserve the right to amend, modify, update, terminate, supplement, or otherwise amend all or any of the Terms, Privacy Policy, rules, or regulations on the Platform at any time by publishing new Terms. Company may, at its sole discretion, also notify the User of any change or modification in these Terms, Privacy Policy, rules, regulations, and terms of use referred to herein or provided by Company, by way of sending an email to the User’s registered email address or posting notifications in the User accounts. The User may then exercise the options provided in such an email or notification to indicate non-acceptance of the modified Terms, rules, regulations, and terms of use referred to herein or provided by Company. If such options to indicate non-acceptance are not exercised by the User within the period prescribed in the email or notification, the User will be deemed to have accepted the modified Terms, rules, regulations, and terms of use referred to herein or provided by Company.</p>
                            <p>5. These terms are made available via the app store or platform that you download our Games from (such as the iOS App Store, Google Play Store, Amazon App Store, Windows Phone Store etc.), or any website or platform where you can play our Games such as <strong style={{ color: 'blue' }}>www.facebook.com</strong>, and on our website at <a style={{ color: 'blue' }} href="www.zamppa.com.">www.zamppa.com.</a> </p>
                            <p>6. You acknowledge and agree that by using, or otherwise accessing the Company Platform and Company Services, you agree to be bound by these Terms and our . You acknowledge that we provide use and access to our Platform and Services to you, subject to these Terms. You agree and acknowledge that you have completely read and understood these Terms and the Privacy Policy, incorporated herein by reference, as amended from time to time. You agree, covenant, and undertake to be bound by the specific rules and regulations of each of the Games as applicable.</p>
                            <p>7. If the User does not accept the modified Terms, rules, regulations, and terms of use referred to herein or provided by Company, we reserve the right to deny access to the Platform and Services to the User. If the User does not accept the modified Privacy Policy, we reserve the right to deny access to the Platform and Services to the User to the extent such modified terms of the Privacy Policy are required to provide access to the Platform and Services.</p>
                            <p>8. Participation in any Games or contest on the Company Platform or viewing of certain Content may be subject to further terms and conditions as may be notified by us from time to time on the Website or otherwise. You should ensure that you have read, understood and agree to abide by the rules and regulations of the Games, contest, tournament available through the Company Platform.</p>
                            <p>9. Company may, at its sole and absolute discretion:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong> Move or remove any Content that is available or posted by a User on the Platform.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong> Establish general practices and limits concerning the use of the Platform and modify these practices, limits and thresholds at its discretion, from time to time, and such changes shall be notified and/or included within updated Terms as deemed fit by Company.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong> Assign its rights and liabilities to all User accounts hereunder to any entity (post such assignment, intimation of such assignment shall be sent to all Users to their registered email addresses).</li>
                            </ul>
                            <p>10. Participation in any Games or contest on the Company Platform or viewing of certain Content may be subject to further terms and conditions as may be notified by us from time to time on the Website or otherwise. You should ensure that you have read, understood and agree to abide by the rules and regulations of the Games, contest, tournament available through the Company Platform.</p>
                            <p>11. You hereby unconditionally and irrevocably, expressly agree to the transfer of your personal data and information (including sensitive data) to Us and to such third parties who we contract with to provide you with the Services.</p>
                            <h3>B. Description of Services Offered</h3>
                            <p>12. Subject to the Users compliance with these Terms, Company gives Users a personal, royalty-free, non-assignable, non-sublicensable, non-transferable, non-exclusive, and revocable limited right to access and use the Platform that is provided as part of the Services. This right is for the sole purpose of enabling the own personal private use from Users to play Games of skill as hosted on the Platform, in the manner prescribed under these Terms and not for any commercial purposes, in India and only until the User is bound by these Terms.</p>
                            <p>13. The Services may change or be modified from time to time without prior notice or communication. Company reserves the right to change, modify or delete the Platform or Services, information, and Terms listed thereon at any time without prior notice.</p>
                            <p>14. The Services may include advertisements, which may be targeted to the Content or information on the Services, queries made through the Services, or any other information. The types and extent of advertising by Company on the Services are subject to change.</p>
                            <p>15. When access Games are included in the Services, the specific rules, scoring, controls and guidelines for each game can be found within the game itself. The Users agree to and shall comply with such rules, scoring, controls and guidelines etc.</p>
                            <p>16. Any charges levied on Users for accessing the Services, including but not limited to internet connection and/or mobile or data charges are solely the responsibility of such Users.</p>
                            <p>17. The Service imposes certain restrictions on its usage, which are outlined below:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong> You must not create an Account or access the Service if you are below the age specified by platforms like Google Play Store, App Store, or Instant Games (Facebook).</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong> It is your responsibility to monitor your Account and prevent minors under the age of 13 (thirteen) from accessing it. You accept full responsibility for any unauthorized use of the Service by minors using your Account, including the use of your payment methods.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong> You are not permitted to have more than one Account per platform or social networking service (SNS) simultaneously. Additionally, you must not create an Account using false information or on behalf of someone else.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>d.</strong> Individuals who are convicted sex offenders are prohibited from creating an Account or using the Service.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>e.</strong> If you have been previously removed or banned by us from playing any of our games, you are not allowed to create a new Account or use the Service.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>f.</strong> Your Account should only be used for non-commercial purposes.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>g.</strong> You are prohibited from using your Account to advertise, solicit, or send any commercial advertisements, including chain letters, junk emails, or repetitive messages (spam) to anyone.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>h.</strong> You must not use your Account to engage in any illegal activities.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>i.</strong>Without our written permission, you are not allowed to sublicense, rent, lease, sell, trade, gift, bequeath, or transfer your Account or any Virtual Items associated with your Account to anyone.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>j.</strong>You are not permitted to access or use an Account or Virtual Items that have been transferred from the original Account creator without our permission.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>k.</strong>If you access the Service through a social networking service (SNS), you must also comply with the SNS’s terms of service/use in addition to these Terms of Service.</li>
                            </ul>
                            <p>18. IN THE GAMES, AS INTRODUCED FROM TIME TO TIME, YOU MAY WIN OR LOSE VIRTUAL CHIPS. THESE VIRTUAL CHIPS HAVE NO REAL CASH VALUE AND CANNOT BE ENCASHED FOR ANY PRIZE, WINNINGS REWARD, CONSIDERATION, PROFIT, OR GAIN EQUIVALENT TO ANY REAL MONEY VALUE WHATSOEVER. PARTICIPATION IN THESE GAMES IS COMPLETELY FREE. IF YOU SPEND ALL YOUR FREE VIRTUAL CHIPS, YOU CAN PURCHASE MORE VIRTUAL CHIPS TO PLAY BY MAKING PAYMENTS INSIDE THE GAME ITSELF. THESE GAMES DO NOT OFFER REAL MONEY GAMBLING, WAGERING OR BETTING OR ANY OPPORTUNITY TO WIN REAL MONEY OR PRIZES.</p>
                            <p>19. Daily/Lifetime Limits:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong> Users of the platform acknowledge and agree that the Company may impose daily/lifetime limits on the number of games that can be played on the application. These specific limits (daily/lifetime) will be determined by the Company and may vary based on factors such as membership level, user activity, and other relevant criteria. The Company reserves the right to change these daily/lifetime limits at its sole and absolute discretion without prior notice to the Users.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong> Exhaustion of limits: Once a User reaches the limit to play a game, they will not be able to play that any game on Our Platform.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong> The Company will keep track of the number of games played by each User for each applicable game.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>d.</strong> Users understand and agree that the Company’s decision to impose, modify, or remove these limits is at its sole and absolute discretion and is not subject to appeal.</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>e.</strong> Users agree to comply with the limits for playing games on the Platform and acknowledge that failure to do so may result in restricted access to certain games on the platform.</li>
                            </ul>
                            <h3>C. User Registration</h3>
                            <p>20. In order to access the Website or fully utilize the Platform offered by the Company, a User is required to register on the Platform. You are not authorised to access any of our Platform, until you have been granted an Account (defined below).</p>
                            <p>21. If you wish to become a registered User, you will need to create an account (‘Account’) on our Platform, have a valid account on the social networking service (‘SNS’) through which you connect to the Service, if any, or have an account with the applications provider for your mobile device. Each User is only permitted to open one Account. Company reserves the right to terminate or suspend any duplicate accounts.</p>
                            <p>22. In order to create or update an Account, Company may require you to register by completing a form and providing certain details, which may include your name, birth date, e-mail address, and in some cases, payment information. This information will be held and used in accordance with Our Privacy Policy and relevant “just-in-time” notices, if any, provided at the point of information collection or use. You agree that you will supply accurate and complete information to Us, and that you will update the information promptly after it changes.</p>
                            <p>23. You understand that on certain Company websites, your user ID number, name, and picture will be publicly available and that search engines may index your name and profile photo.</p>
                            <p>24. You can also register or log in through your Google e-mail address or using a guest log-in. Permission to open the Account shall be determined by Company or its representatives, at their sole discretion. We may refuse to grant an application for an Account made by any person without giving a reason for such refusal. You agree and acknowledge that the decision of Company in this regard shall be final.</p>
                            <p>25. Your information, and the contents of all of your online communications (including without limitation chat text, voice communications, IP addresses and your personal information) may be accessed and monitored as necessary to provide the Service and may be disclosed: (i) when We have a good faith belief that We are required to disclose the information in response to legal process (for example, a court order, search warrant or subpoena); (ii) to satisfy any applicable laws or regulations (iii) where We believe that the Service is being used in the commission of a crime, including to report such criminal activity or to exchange information with other companies and organizations for the purposes of fraud protection; (iv) when We have a good faith belief that there is an emergency that poses a threat to the health and/or safety of you, another person or the public generally; and (v) in order to protect the rights or property of Company, including to enforce our Terms of Service. By entering into these Terms of Service, you hereby provide your irrevocable consent to such monitoring, access, and disclosure.</p>
                            <h3>D. Eligibility</h3>
                            <p>26. You warrant that you are not prohibited from receiving products of Indian origin, including services or software.</p>
                            <p>27. You must be over 13 years of age to access and/or use our Platform and create an Account with us. By agreeing to these Terms, you represent and warrant to us that: (a) you are at least 13 years old; (b) you have not previously been suspended or blocked from using the Platform; and (c) your registration and your use of the Platform is in compliance with any and all applicable laws and regulations.</p>
                            <p>28. If you under the age of 18 (eighteen), you represent that your parent/legal guardian has reviewed and agree to these terms.</p>
                            <p>29. We reserve the right to request proof of identity at any stage to verify that minors are not using the Platform and we may exclude a person from accessing or using the Platform if proof of identity is not provided or if Company suspects that a person is under the age of 13 years.</p>
                            <p>30. Parents and guardians should monitor their children’s / ward’s online activities and consider using parental control tools available from online services that help provide a child an amicable online environment. These tools can also prevent children from disclosing any personal information without parental permission.</p>
                            <p>31. You agree to provide true, accurate, current, and complete information at the time of registration and at all other times (as required by Company). You agree to update and keep updated all information provided for the purposes of registration. You undertake and agree that all information provided by you from time to time is true, accurate, current, and complete to your personal knowledge.</p>
                            <p>32. In case you are registered with the ‘Zamppa’ application offered by us, you shall not be allowed to create an account on the Zamppa Free’ application. The Company reserves the sole discretion to terminate or suspend any accounts found to be registered on both platforms.</p>
                            <h3>E. Creation of Account and Use of the Platform</h3>
                            <p>33.Upon grant of the Account by us, your Account will be created, and you will be able to use our Platform, access the Service(s) and play the Game(s) hosted by us. You agree and acknowledge that such Account can be terminated by us by giving notice to you, for any reason deemed fit by Company in its sole discretion.</p>
                            <p>34.You represent and warrant to us that, throughout the course of your usage of our Platform, you will not permit other persons to access or use your Account. If you permit other persons to access or use your Account, you do so at your own sole risk as to any consequences. You further agree and accept that you shall not access or use the Platform through the Account of another User.</p>
                            <p>35.Users are prohibited from operating multiple accounts. If it comes to the knowledge of Company that a User is operating multiple accounts, in such case, Company reserves the right to restrict, ban, suspend or terminate such User’s Accounts, at its sole and absolute discretion and without any notice. Any cash or credit balances in such User’s Accounts is liable to be consolidated into the Account with the highest cash or credit balances or forfeited at the sole discretion of Company, depending on whether such Accounts are aliases or Accounts associated with actual or potential frauds.</p>
                            <p>36.By accepting these terms and conditions upon registration, you give consent to Company to disclose your personal information provided to us to a third-party agency to assist in verifying your identity. The third-party agency may prepare and provide Company with such an assessment and may use your personal information including the names, residential addresses and dates of birth, financial information etc. for the purposes of preparing such an assessment. Please refer to our Privacy Policy in this regard.</p>
                            <p>37.You acknowledge that your participation in any Game(s) available on the Platform is purely voluntary and at your sole discretion and risk.</p>
                            <p>38.We verify User(s) location by logging of IP address for location mapping and User(s) hereby consents for collection of aforementioned information. The Company reserves the right to delete/remove such Account(s) without any notice and in its sole and absolute discretion.</p>
                            <p>39.When You deposit money to Your wallet on the Platform by using a Virtual Payment Address (VPA) of the Unified Payments Interface (UPI) payment method, We may store such VPA to facilitate seamless and swift deposit transactions for You in the future and for enhancing Your payment experience. You understand, agree and consent to such storing of the VPA by the Platform.</p>
                            <p>40.The Platform may also, from time to time, through the Payment System Providers, fetch other VPAs associated with Your mobile number registered with the Platform. These VPAs will be used to facilitate seamless and swift deposit transactions for You and for enhancing Your overall payment experience. You understand, agree and consent to the Platform obtaining such VPAs from relevant Payment System Providers and storing and displaying such VPAs to You on the Platform.</p>
                            <h3>F. Third Party Advertisements</h3>
                            <p>41. You acknowledge that the Service and Company’s games may display advertisements from Company or third parties. The way Company discloses information for third-party advertising is explained in Company’s Privacy Policy.</p>
                            <p>42.The Service may contain links to third-party websites or vendors that may offer you a promotional deal in exchange for optional components of the Service or upgrades (such as in-game currency). Any charges or obligations you incur in your interactions with these third parties are solely your responsibility. Company does not guarantee or warrant the content, goods, and/or services provided by any third party, even if they are linked to from our Service, and Company will not be held liable for any claims related to such third-party content, goods, and/or services. These linked sites are not controlled by Company and may gather data or request personal information from you. Company is not responsible for the content, business practices, or privacy policies of these sites, nor for the collection, use, or disclosure of any information they may gather. Additionally, the inclusion of any link does not imply endorsement by Company of the linked sites. </p>
                            <h3>G. Security</h3>
                            <p>43. Access to your Account and to add, delete or modify any Content on your Account is password / OTP protected.</p>
                            <p>44. We are not liable for any loss, damages or expenses of any kind incurred or suffered by you arising from or in connection with any failure, delay or interruption, interception, interference, in transmitting passwords/OTPs due to any failure of the mobile service network or any other circumstance beyond our reasonable control.</p>
                            <p>45. You agree that you will not disclose your password / OTP to anyone or allow any other person to access or use your Account. We are not responsible if your Account is accessed or opened by other persons using your password. You shall be held solely responsible and liable for all of your activities on the Platform made from your Account.</p>
                            <p>46. You understand that it is your obligation to protect the information you have provided on the Platform including, but not limited to usernames, passwords, email addresses, contact details, bank account information etc. You explicitly understand and agree that we will not incur any liability for information provided by you to anyone which may result in your Account on the Platform being exposed or misused by any other person or any other harm or loss being suffered by you. </p>
                            <p>47. Provided that Company or its employees have not engaged in fraud or gross negligence, Company shall not be liable to a User for any authorised transaction which has been executed through the Platform as a result of:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong>fraud committed by any User using User’s access credentials before the User has notified Company in writing of the loss or theft or such access credentials , as a result of which Company has been unable to take any action to disable the access credentials;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong>fraud committed by third parties against Users which may not be preventable by, or occur despite the security systems of the Platform;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong>the security, accuracy, legality, appropriateness or any other aspect of the Content or function of any third party’s products or services on our Platform;</li>
                            </ul>
                            <p>48. Please also contact us immediately if your mobile or computer device through which you access the Platform has been lost, stolen, or compromised in any way or someone has used or may use your mobile or computer device or credentials without your authorisation or if you become aware of any unauthorised use of your Account.</p>
                            <p>49. You should only transact with a third- party product or service that you know.</p>
                            <p>50. Please note that we can terminate or suspend your Account and all associated accounts, institute fraud prevention measures such as access restrictions, transaction velocity restrictions etc., without prior notice to prevent unauthorised use of our Platform. You must co-operate with us in any investigation and use any fraud prevention measures we prescribe, and you agree that we will not be liable to you or any third party for any block, suspension, cancellations, or termination of your use of an Account owing to such measures.</p>
                            <p>51. You are hereby informed that any and all information associated with any fraudulent transaction may be shared by Company (with or without demand) with the Government of India or any agency thereof, for the purpose of verification of the identity of User, or for prevention, detection, investigation, prosecution, and punishment.</p>
                            <h3>H. User Conduct</h3>
                            <p>52. In accessing or using our Platform you agree that you will not:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong>use any automated device, software, process or means to access, retrieve, scrape, or index our Platform or any Content thereon without our express prior written consent;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong>use any device, software, process or means to interfere or attempt to interfere with the proper working of our Platform;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong>undertake any action that will impose a burden or make excessive traffic demands on our infrastructure that we deem, in our sole discretion, to be unreasonable or disproportionate site usage;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>d.</strong>use or index any Content or data on our Platform for purposes of competing with us in any manner that we have not specifically authorised; transmit spam, chain letters, games, junk email, surveys, or other mass messaging, whether commercial in nature or not;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>e.</strong>use our Platform or any Content there from in any manner which is, in our sole discretion, not reasonable and / or not for the purpose it is made available;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>f.</strong>violate the rights of any person, including copyright, trade secret, privacy right, or any other intellectual property or proprietary right;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>g.</strong>pose as any person or entity or attempt to solicit money, passwords or personal information from any person;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>h.</strong>act in violation of any Terms of using our Platform;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>i.</strong>reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works or adaptations of, publicly display, sell, trade, or in any way exploit our Platform or any Content thereon, except as expressly authorised by us; or</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>j.</strong>transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses or other items of a destructive nature.</li>
                            </ul>
                            <p>53. If you choose to submit any content by way of question on the Platform, you hereby warrant that no Contribution (defined hereinafter) shall:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong>be considered to be, unlawful, threatening, abusive, bigoted, hateful, libelous, tortious, blasphemous, false or misleading, discriminatory, defamatory, obscene, vulgar, offensive, excessively violent, invasive of another’ privacy, publicity, contract or other rights, pornographic or inclusive of nudity, pedophilic, profane, sexually explicit, indecent, racially, ethnically objectionable, disparaging, relating or encouraging money laundering, or otherwise unlawful in any manner whatsoever;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong>constitute, advocate or encourage conduct that would constitute or give rise to a criminal offense, civil liability or other violation of any local, state, national or international law;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong>constitute or contains false or misleading indications of origin or statements of fact;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>d.</strong>belongs to another person and to which you do not have any right to, or infringe the intellectual property rights (whether registered or unregistered) of Company or any third party;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>e.</strong>contain any information, software or other material of a commercial nature or is patently false or untrue;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>f.</strong>vcontain advertising, promotions or commercial solicitations of any kind;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>g.</strong>harass or harm another person;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>h.</strong>exploit or endanger a minor;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>i.</strong>rimpersonate or attempt to impersonate any person or entity or mislead or attempt to mislead another person regarding your identity;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>j.</strong>threaten the unity, integrity, defence, security or sovereignty of the territory, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting any other nation.</li>
                            </ul>
                            <p>54. As it concerns the content uploaded by you, without prejudice to your obligation to otherwise comply with applicable laws during the course of using the Platform, you agree to hereby comply with any and all applicable laws, as well as any other rules and restrictions that may be set forth herein or on the Platform. Company may not be held responsible for any content posted, or contributed by Users on the Platform.</p>
                            <p>55. The Users admit that they may be exposed to Content posted by other Users which they may find offensive, obnoxious, or indecent and that we may not be able to prevent such Content from being posted. Such Content should be brought to our notice. We shall act upon the information as it deems acceptable. The decision taken by the management of the Company in this regard shall be final and binding on the User and you specifically agree that we shall not incur any liability under any circumstance whatsoever regarding the same.</p>
                            <p>56. We reserve the right to prevent unauthorised access to or use of our Platform, including, but not limited to, instituting technological barriers, or reporting your conduct to any person or entity.</p>
                            <p>57. In the event, we have reasonable grounds to believe that your activities include any of the acts specified above, we may initiate appropriate legal action against you as well as notify the relevant regulatory or law enforcement authorities where appropriate in addition to any other available remedies under law or equity, apart from restricting or suspending or terminating your use of the Platform.</p>
                            <p>58. You shall not purchase, sell, assign, trade, rent, loan, lease, license, grant a security interest in, or transfer your User Account, any content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the Platform.</p>
                            <p>59. Users agree to abide by these Terms and all other rules, regulations, and terms of use of the Platform. In the event User does not abide by these Terms and all other rules, regulations, and terms of use, Company may, at its sole and absolute discretion, take necessary remedial action, including but not limited to:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>a.</strong> restricting, suspending, or terminating any User’s access to all or any part of Services;</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>b.</strong> deactivating or deleting a User’s Account and all related information and files on the Account. Any amount remaining unused in the User’s game Account or winnings account on the date of deactivation or deletion shall be transferred to the User’s bank account on record with Company net off GST paid on amount deposited by the User w.e.f 01.10.2023 and subject to a processing fee (if any) applicable on such transfers in relation to gaming, as set out herein; or</li>
                                <li>&nbsp; &nbsp;&nbsp;&nbsp;<strong>c.</strong> refraining from awarding any prize(s) or withholding access to such infringing User.</li>
                            </ul>
                            <p>60. Users agree to ensure that they can receive all communication from Company by marking e-mails or push notifications. Company shall not be held liable if any e-mail/push notification remains unread by a User as a result of such e-mail getting delivered to the User’s junk or spam folder or user not receiving push notification If a User chooses a username that, in Company’s considered opinion is obscene, indecent, abusive or that might subject Company to public disparagement or scorn, Company reserves the right, without prior notice to the User, to change the User’s username and inform the User, or delete such username and posts from Platform, deny such User access to Platform, or any combination of these options.</p>
                            <p>61. Unauthorized access to Platform is a breach of these Terms, and a violation of the law. Users agree not to access the Platform by any means other than through the interface that is provided by Company for use in accessing Platform. Users agree not to use any automated means, including, without limitation, agents, robots, scripts, or spiders, to access, monitor, or copy any part of our sites, except those automated means that we have approved in advance and in writing.</p>
                            <p>62. The use of the Platform is subject to existing laws and legal processes. Nothing contained in these Terms shall limit Company’s right to comply with governmental, court, and law-enforcement requests or requirements relating to User’s use of the Platform.</p>
                            <p>63. Company may not be held responsible for any content contributed by Users on the Platform.</p>
                            <p>64. You will not use the Service(s) or any content provided by Company, or in connection with, or in any manner whatsoever, that relates to, or encourages or promotes, the activity of money laundering, gambling, betting, wagering or is otherwise unlawful or illegal in any manner whatsoever, under any Applicable Law. The Service(s) provided by Company are intended for entertainment purposes only and they do not, in any way, promote any form of gambling, wagering, or betting. Company is not, and shall not be held to be responsible, directly, or indirectly, for any form of betting, wagering, or gambling acts/ activities that may take place outside these Terms as provided herein and hereby expressly disclaims any and all liability in connected therewith.</p>
                            <p>65. Company reserves the right to deny its Platform/ Services to any User at its sole and absolute discretion. Such denial would automatically disqualify a User from playing any Games hosted on the Platform.</p>
                            <p>66. The User represents and warrants that the documents provided during the verification process are true copies of the original documents to which they relate.</p>
                            <p>67. Users are required to provide proper and complete details at the time of registration. Company shall not be responsible for communications errors, commissions or omissions including those of the Users due to which the results may not be communicated to the Winner.</p>
                            <h3>I. Prevention of Collusion and Fraud</h3>
                            <p>68. We are committed to promoting <strong style={{ color: "blue" }}>fair play</strong> on our Platform.</p>
                            <p>69. Our compliance team will track game play of all Users and strict action will be taken against any User(s) caught colluding. By agreeing to these Terms, you agree not to indulge in any unfair game practice that may provide undue advantage to you or any other User..</p>
                            <p>70. In the event any User is found undertaking such activity on the Platform, Company shall be entitled, at its sole and absolute discretion, to suspend or terminate such User’s account. Company reserves the right to permanently block the account of the User involved in such activity. Any amounts deposited by such User on the Platform shall stand forfeited. Company reserves the right to impose monetary penalties on Users found to be undertaking in such practices on the Platform.</p>
                            <p>71. We reserve the right to withhold any necessary action, as it deems fit, against Users found indulging in unfair means. The action could include, inter alia, monetary penalties, temporary account suspension, redemption blocking and/or permanent account deactivation. Any User involved in violation of these Terms is liable for prosecution.</p>
                            <p>72. By using the Platform, including any sub-domains of the Platform, or related top-level domains including mobile sites, apps, APIs and widgets and/or registering yourself with us, you authorize us or our representatives to contact you via email or phone call or SMS including third party messaging apps to offer you our services including but not limited to impart product knowledge, offer/explain any promotional offers running on the Platform and offers offered by third parties, for which reasons, personally identifiable information may be collected. Irrespective of the fact that you have registered yourself under DND or DNC or NCPR service, you still authorize us or our representatives to contact you by any means aforementioned for the above-mentioned purposes or any other related purpose till the time you are registered with us or even after cessation of registration.</p>
                            <h3>J. Intellectual Property</h3>
                            <p>73. The Platform includes a combination of Content created by Company, its partners, affiliates, licensors, associates and/or Users. The intellectual property rights <strong>(Intellectual Property Rights)</strong> in all software underlying Company and the Platform and material published on the Platform, including (but not limited to) Games, games software, advertisements, written Content, photographs, graphics, images, illustrations, marks, logos, audio or video clippings and flash animation, is owned by Company, its affiliates, partners, licensors and/or associates. Users may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works of, distribute, publicly perform, publicly display, or in any way exploit any of the materials or Content on Company either in whole or in part without express written consent from Company. Nothing in these Terms shall be construed to convey and the Users by virtue of these Terms shall not acquire any ownership or other interest or right in any intellectual property any of the materials or content on the Platform.</p>
                            <p>74.Users may request permission to use any Content by writing in to helpdesk at
                                <a style={{ color: 'blue' }} href="mailto:support@zamppa.com"> support@zamppa.com</a></p>
                            <p>75. Users are solely responsible for all materials (whether publicly posted or privately transmitted) that they upload, post, e-mail, transmit, or otherwise make available on the Platform <strong>(Users Content)</strong>. Each User represents and warrants that he/she owns all Intellectual Property Rights in the User’s Content and that no part of the User’s Content infringes any third-party rights. Users further confirm and undertake to not display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights of any third party on the Platform. </p>
                            <p>76. Users agree to indemnify and hold harmless Company, its directors, officers, employees, affiliates and assigns from and against all costs, damages, loss and/ or harm including towards litigation costs and counsel fees, in respect of any third party claims that may be initiated including for infringement of Intellectual Property Rights arising out of such display or use of the names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on Company, by such User or through the User’s commissions or omissions. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by you in which event you will assist and cooperate with Company in asserting any available defences.</p>
                            <p>77. Users hereby grant to Company and its affiliates, partners, licensors and associates a worldwide, irrevocable, perpetual, royalty-free, non-exclusive, sub-licensable, license to use, store, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish Users’ Content for any of the following purposes:</p>
                            <ul>
                                <li>&nbsp; &nbsp;&nbsp;<strong>a.</strong> displaying Users’ Content on Platform;</li>
                                <li>&nbsp; &nbsp;&nbsp;<strong>b.</strong> distributing Users’ Content, either electronically or via other media, to other Users seeking to download or otherwise acquire it; and/or</li>
                                <li>&nbsp; &nbsp;&nbsp;<strong>c.</strong> storing Users’ Content in a remote database accessible by end Users, for a charge.</li>
                            </ul>
                            <p>78. This license shall apply to the distribution and the storage of Users’ Content in any form, medium, or technology.</p>
                            <p>79. The license shall not lapse due to non-use by Company whether under any provision of the Copyright Act, 1957 or any other law.</p>
                            <p>80. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Platform belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners and any claims, controversy or issues against these names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights must be directly addressed to the respective parties under notice to Company.</p>
                            <h3>K. Testimonial Terms</h3>
                            <p>81. Company may capture and obtain visual and/or audio recordings or performances, still images of the User, and text provided by the User for the testimonial being provided towards the Platform (the results of which are the <strong>Recordings</strong> ). The Recordings provided by the User are voluntary and Company is the sole and exclusive owner of all rights in and to the Recordings, and all elements thereof (including, without limitation, the copyright thereto). Without prejudice to the above, the User grants to Company a perpetual royalty-free license, to publicly use, distribute, reproduce, create derivative works from, and perform/display the user’s name, voice, likeness, appearance, biographical information, and other indicia of its identity as included in the Recordings and any excerpts or version thereof for their marketing, in any language, and without any geographical limitation. Company may incorporate the recordings and any separate content (e.g., quotes, photos, videos, artwork, materials, etc.) to market their Platform and Services. The User also hereby waives any right to inspect or approve the finished matter based on the Recording that may be used now or in the future, whether that use is known to the user or unknown.</p>
                            <p>82. Company is not obligated to use the Recordings if it so wishes. The User will not attempt to enjoin or otherwise impair Company’s use of the Recordings that is in accordance with this Release.</p>
                            <h3>L. ADVERTISING</h3>
                            <p>83. We reserve the right to use and disclose the collected, non-personal data for purposes of advertising by us or our partners and contractors. We may employ third parties for ad serving technologies who may use certain methods to collect information as a result of ad serving through services.</p>
                            <p>84. We and these entities may use various identifiers to collect information about your use of the App, including but not limited to your IP address, MAC address, device identifiers, software and hardware information, time zone and usage information. This information may be used by us and others to, among other things, determine the popularity of certain content, deliver contextual advertising, and content and better understand your activity on the Apps.</p>
                            <p>85. Third-party ad serving technologies are integrated into the App, so if you do not want to be subject to these technologies, please do not use or access the Apps.</p>
                            <h3>M. Third Party Sites, Services and Products</h3>
                            <p>86. Platform may contain links to other Internet sites owned and operated by third parties. Users’ use of each of those sites is subject to the conditions, if any, posted by such third-party sites. Company does not exercise control over any Internet sites apart from Company and cannot be held responsible for any content residing in any third-party Internet site. Company’s inclusion of third-party Content or links to third-party Internet sites is not an endorsement by Company of such third-party Internet site.</p>
                            <p>87. Users’ correspondence, transactions/offers or related activities with third parties, including payment providers and verification service providers, are solely between the User and that third party. Users’ correspondence, transactions and usage of the services/offers of such third party shall be subject to the terms and conditions, policies and other service terms adopted/implemented by such third party, and the User shall be solely responsible for reviewing the same prior to transacting or availing of the services/offers of such third party. User agrees that Company will not be responsible or liable for any loss or damage of any sort incurred as a result of any such transactions/offers with third parties. Any questions, complaints, or claims related to any third-party product or service should be directed to the appropriate vendor.</p>
                            <p>88. The Platform contains Content that is created by Company as well as content provided by third parties. Company does not warranty the accuracy, integrity, quality of the content provided by third parties and such content may not be relied upon by the Users in utilizing the Services provided on the Platform including while participating in any of the Games hosted on the Platform.</p>
                            <h3>N. Privacy</h3>
                            <p>89. By using the Platform and providing any of your personal information to the Platform, You affirmatively consent and agree to comply with our Privacy Policy (link given above), guidelines and statements as may be applicable from time to time, which are incorporated into and forms an integral part of these Terms. If you do not agree to the terms of the Privacy Policy in its entirety or have objections to the use of your information, you may not access or otherwise use the Platform or its Services.</p>
                            <h3>O. O. Disconnection and Technical Issues</h3>
                            <p>90. You understand and acknowledge that once a game has commenced, not being able to play due to slow internet connections, faulty hardware, technical failure due to customer’s hardware, internet connection failure, low computer configuration or for any other reason not attributable to us does not require us to issue a refund of the participation amount you may have paid for participation.</p>
                            <p>91. In case of any technical failures, server crashes, breakdowns, software defects, disruption or malfunction of service at our end, as a policy, we will cancel the Game(s) and refund the participation amounts after proper verification and no service fee will be charged for such Games and you accept that we are not responsible to you in all such cases. For any game, we have the right to cancel and refund the participation amount. In no case, other than a server crash, are we accountable for any of the User’s disconnections from the server. We are also not liable for any prospective Winnings from any incomplete game.</p>
                            <p>92. We do not hold any liability to any disconnection, lag, freeze or interference in network on the User’s computer or any other external networks</p>
                            <h3>P. Enforceability</h3>
                            <p>93. By registering, you are considered to have accepted and understood all the Terms herein and the relevant Game specific rules and regulations. We recommend that you keep a copy of all transactions, gaming rules, cancellation and payment policies. Our failure or delay to act or exercise any right or remedy with respect to a breach of any of these Terms by you does not amount to surrendering or waiving our rights to act with respect to any prior, concurrent, subsequent or similar breaches.</p>
                            <p>94. If any judicial or quasi-judicial body in India declares any of the provisions of these Terms to be unlawful, invalid, void or unenforceable for any reason, the validity and enforceability of the remaining provisions will not be affected. Any such inappropriate term or condition will be replaced with another term or condition that is valid and enforceable and is in most nearly with effect to the original invalid term. In such event, the parties shall be obligated to construe and interpret the void, illegal or unenforceable provision with a valid, legal and enforceable provision in such manner that corresponds as closely as possible to Company’s intent and objective in the void, illegal or unenforceable provisions.</p>
                            <h3>Q. Disclaimer of Warranties</h3>
                            <p>95. Company disclaims any and all warranties, expressed or implied, in connection with the underlying software of the Platform, the Website and the Game(s), all of which are provided to the User as is.</p>
                            <p>96. You agree that use of the Services and the access to the Platform is at Your own risk. The Services including the Platform, the Content, the Games, and any other materials contained on or provided through the Platform are provided “AS IS” and, to the fullest extent permitted by law, are provided without warranties of any kind, either express or implied. Without limiting the foregoing, Company does not make any warranties of fitness for a particular purpose, title, merchantability, completeness, availability, security, compatibility, or non-infringement; or that the Services will be uninterrupted, free of viruses, malware or other harmful components, accurate, error free or reliable.</p>
                            <p>97. No person affiliated or claiming affiliation with the Platform has authority to extend such warranties.</p>
                            <p>98. Without limiting the generality of the foregoing, to the maximum extent permitted by applicable law, we shall have no liability for any damages or injury caused, in whole or in part, by contingencies or issues beyond our reasonable control, including, but not limited to: the acts of third parties, errors in the Content or the Platform, network failures, internet failures, software and hardware failures, viruses and other system attacks, labor stoppages, riots, acts of government or god, natural disasters, acts of terrorism, communication line failure, or theft, destruction of, unauthorized access to, alteration of or use of records.</p>
                            <p>99. To the maximum extent permitted by applicable law, you hereby agree that you shall have no remedy in respect of any statement, representation, assurance or warranty (whether made innocently or negligently) that is not set out in these Terms. You agree that you shall have no claim for innocent or negligent misrepresentation or negligent misstatement based on any statement in these Terms.</p>
                            <p>100. The foregoing limitations shall apply regardless of whether (i) liability or damage is alleged for breach of contract, tortious behaviour, negligence, or under any other theory or cause of action, and/or (ii) the party against which liability or damages is sought was advised of the possibility thereof. For the avoidance of doubt, nothing in these Terms shall limit or exclude liability for (i) death or personal injury caused by negligence; or (ii) fraud.</p>
                            <p>101. To the maximum extent permitted by applicable law, you agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to these Terms, the Platform, or any part thereof, must be asserted within one (1) year after such claim or cause of action arose, or it shall be forever barred.</p>
                            <p>102. You agree and acknowledge that Company does not promise or ensure that you will be able to access your Account whenever you want. It is entirely possible that you may not be able to access your Account, or the Platform provided by Company at times or for extended periods of time due to, but not limited to system maintenance and updates.</p>
                            <p>103. Company makes no guarantees, warranties, representations, or other promises relating to the game(s) and assumes no liability or responsibility for any financial loss that you may sustain as a result of participation in the Game(s).</p>
                            <p>104. You will be solely responsible for any delay and/or damage to your computer systems or loss of data that results from the installation of any software on your computer, and we expressly disclaim liability for any such failure and/or delay.</p>
                            <p>105. You agree to assume the entire risk as to the results and performance of any software and/or Game(s) availed by you on the Platform. As such, the result and performance among other things depends on your internet connection and hardware. You also assume the entire cost of all servicing, repair and/or correction of your hardware when you access, download, or execute software or Game(s) available on the Platform. We explicitly disclaim liability for any delay or failure to perform resulting from installation of any software on your computer.</p>
                            <p>106. You specifically acknowledge, agree and accept that we are not liable to you for: (i) the defamatory, offensive or illegal conduct of any other User or for anything that turns out to be misleading, inaccurate, defamatory, threatening, obscene or otherwise illegal whether originating from another User or otherwise; (ii) any loss whatsoever arising from the use, abuse or misuse of your Account on our Platform; (iii) any loss incurred in transmitting information from you to our Platform by the Internet or by other connecting media; (iv) any technical failures, system breakdowns, defects, delays, interruptions, manipulated or improper data transmission, loss or corruption of data or communications’ lines failure, distributed denial of service attacks, viruses or any other adverse technological occurrences arising in connection with your access to or use of our Platform; (v) the accuracy, completeness or currency of any information services provided or any statistics shown on the Platform.</p>
                            <h3>R. Indemnification and Limitation of Liability</h3>
                            <p>107. You clearly understand and agree that Company shall under no circumstances (including, without limitation, in contract, negligence or other tort), be liable for any direct, indirect, ancillary, special, incidental, consequential or exemplary or punitive damages. This includes, but is not limited to injury, claim, loss of data, loss of income, loss of profit or loss of opportunity, loss of or damage to property, damages for monetary loss, goodwill, use, data or other intangible loss (even if Company has been advised of the possibility of such damages), resulting from or arising out of the use of or the inability to use the Platform, even if we have been advised or become aware of the possibility of such damages or loss or that such loss was foreseeable.</p>
                            <p>108. You agree to indemnify us and each of our respective general and limited partners, Users, shareholders, directors, officers, employees, agents, representatives, vendors and business partners harmless from and against any and all claims, liabilities, obligations, losses, costs or debt, damages and expenses (including attorneys’ fees and court costs) arising out of or relating to:</p>
                            <ul>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>a. </strong>breach of these Terms, in tort (including negligence) third party claims or liabilities arising against Company out of such a breach, based in contract, tort, statute, fraud, misrepresentation, or any other legal theory, and regardless of whether a claim arises during or after the termination of these Terms</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>b. </strong>your use of the Platform in any matter that is contrary to applicable laws, with an intent to deceive, defraud cheat, mislead or solicit any business, monetary or non-monetary consideration or information from another User</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>c. </strong>your breach of any applicable laws or governmental or judicial order which applies to your use of the Platform from a specific geography</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>d. </strong>your use of the Platform, including but not limited to your posting, use of, modification or interaction with any content on the Platform</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>e. </strong>any unauthorized, improper, illegal or wrongful use of your Account by any person, including a third party, whether or not authorized or permitted by you</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>f. </strong>your User Content</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>g. </strong>use by any other person accessing the Platform using your username or password, whether or not with your authorization</li>
                                <li>&nbsp;&nbsp;&nbsp;&nbsp;<strong>h. </strong>the use by us of information provided by you through our Platform.</li>
                            </ul>
                            <p>109. This indemnification obligation will survive the expiry or termination of these Terms and your use of the Platform.</p>
                            <p>110. There could be instances where Company is not able to receive prompt updates about the change in applicable laws of your place of stay. It is your obligation to ensure that any activities you engage on the Platform are legal as per the applicable laws. You agree to indemnify and hold harmless, Company or its Platform, from any claim, demand, damage, or loss whatsoever coming out due to your non-compliance with the laws of your jurisdiction.</p>
                            <h3>S. Termination</h3>
                            <p>111. We may change, suspend or discontinue any aspect of the Platform at any time, including the availability of any Platform’s feature, database, or Content. We may also impose limits on certain features and services or restrict your access to parts or the Platform, without notice or liability at any time in our exclusive discretion, without prejudice to any legal or equitable remedies available to us, for any reason or purpose. However, under normal circumstances, we will only do so where there has been conduct that we believe violates these Terms or other rules and regulations or guidelines posted on the Platform or conduct which we believe is harmful to other Users, to our businesses, or other information providers.</p>
                            <p>112. Upon any termination of these Terms, you shall immediately discontinue your use and access to the Platform and destroy all materials obtained from it. You hereby agree and consent to the above and agree and acknowledge that we can, at our sole discretion, exercise our right in relation to any or all of the above, and that we shall not be liable in any manner of the same; and you agree, acknowledge and consent to the same.</p>
                            <p>113. You acknowledge that your representations, undertakings, and warranties and the clauses relating to indemnities, limitation of liability, grant of license, governing law and jurisdiction, confidentiality shall survive the efflux of time and the termination of these Terms.</p>
                            <h3>T. Publicity</h3>
                            <p>114. Promotional activities may include but not be limited to press events, internal meetings, and ceremonies/functions.</p>
                            <h3>U. Dispute Resolution</h3>
                            <p>115. In case you have any grievances with respect to the Platform or the Services, you can send an email to the Grievance Officer <strong> Mr. Rakesh kumar</strong> (hereinafter referred to as the <strong> Grievance Officer</strong> ) at <a style={{ color: 'blue' }} href="mailto: support@zamppa.com"> grievances@zamppa.com </a>. Further, you can write to the Nodal Officer <strong> Mr. Adarsh kumar</strong> hereinafter referred to as the <strong>Nodal Officer</strong> ) at <a href="mailto: support@zamppa.com">support@zamppa.com</a>. We reserve the right to replace the Grievance Officer and Nodal Officer at any time without any notice or intimation to you. You can directly escalate any disputes to our management team by contacting the Grievance or Nodal Officer.</p>
                            <p>116. Subject to the clauses below, the courts of competent jurisdiction at Haryana shall have exclusive jurisdiction to determine any and all disputes arising out of, or in connection with the Platform provided by Company (including the games, construction, validity, interpretation and enforceability of these Terms, or the rights and obligations of the Users), as well as the exclusive jurisdiction to grant interim or preliminary relief in case of any dispute referred to arbitration as given below. All such issues and questions shall be governed and construed in accordance with the laws of India.</p>
                            <p>117. In the event of any legal dispute (which may be a legal issue or question) which may arise, the party raising the dispute shall provide a written notification ( <strong>Notification</strong> ) to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within fifteen (15) days of receipt of Notification, the dispute shall be settled by arbitration.</p>
                            <p>118. The seat and venue of arbitration shall be indore Madhya pradesh , India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time. The parties shall mutually appoint a sole arbitrator.</p>
                            <p>119. The arbitration award will be final and binding on the parties, and each party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise.</p>
                            <p>120. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts of Gurgaon. The arbitrator shall give a reasoned award.</p>
                            <p>121. Nothing contained in these Terms shall prevent Company from seeking and obtaining interim or permanent equitable or injunctive relief, or any other relief available to safeguard its interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any courts of competent jurisdiction at Haryana to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of Company to pursue any remedy for monetary damages through the arbitration described herein.</p>
                        </div>
                    </div>
                </div>
            </section >
            {/* Footer area */}
            {/* <div className="copyright-area ptb-70 pb-4">
                <div className="container">
                    <div className='f_top row' >
                        <div className="col-md-5">
                            <a href="/"> <img src="img/icon/logo.svg" className='img-fluid' /> </a>
                        </div>
                        <div className='col-md-7' >
                            <div className='dd_row' >
                                <h5 className='text-white' >  Download App From</h5>
                                <div class="dn_btn">
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_1.svg" alt="Zamppa" />
                                    </a>
                                    <a href="https://api.zamppa.com/v1/admin/download" class="btn_blank">
                                        <img decoding="async" src="/img/play_2.svg" alt="Zamppa" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="conct-border  align-items-start">
                        <div className='row' >
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Info</h5>
                                    <a href="/about">About Us  </a>
                                    <a href="/termsconditions">Terms & Conditions</a>
                                    <a href="/privacypolicy">Privacy Policy</a>
                                    <a href="/refund-policy">Refund and Cancallation Policy </a>
                                    <a href="/no_bot">No Bots Certification </a>
                                    <a href="/free_policy">Zamppa Free Policy </a>
                                    <a href="/responsible-gaming-policy"> Responsible Gaming  </a>
                                    <a href="/fairplay">Zamppa Fairplay</a>
                                    <a href="/free_terms">Zamppa Free Terms & Conditions</a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Games</h5>
                                    <a href="/ludoClassic">Ludo Classic </a>
                                    <a href="/ludoExpress">Ludo Express </a>
                                    <a href="/ludoUltra">Ludo Ultra </a>
                                </div>
                            </div>
                            <div className="col-6 col-md-4 col-lg-2">
                                <div className="single-address">
                                    <h5>Quick Links</h5>
                                    <a href="/how_to_play">How to Play</a>
                                    <a href="/rng_certificate">RNG Certificate </a>
                                    <a href="/contact">Contact Us</a>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-6">
                                <div className="single-address">
                                    <h5>About Zamppa</h5>
                                    <p className='mb-3' >We are the fastest-growing online Ludo gaming platform, where you can win real-money prices. We have made Ludo more interesting and fun with multiple-level challenges and winning prices. We aim to fill your life with entertainment while challenging your IQ.
                                    </p>
                                    <div className=" contact-social  ">
                                        <ul>
                                            <li>
                                                <a target='_blank' href="https://www.instagram.com/zamppa_official/profilecard/?igsh=MWJkaTEwZGlwc3BzZQ=="><i class="ri-instagram-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://www.facebook.com/share/1F7YjKNkrt/?mibextid=LQQJ4d"><i class="ri-facebook-line"></i></a>
                                            </li>
                                            <li>
                                                <a target='_blank' href="https://youtube.com/@zamppaofficial?si=dglr23Bj3gGuz2YT"><i class="ri-youtube-line"></i></a>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright-area copyright_area_two text-center">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2024 Copyright to Zamppa Games.</p>
                        </div>
                    </div>
                </div>
            </div> */}
            <Footer/>
        </>
    )
}

export default FreeTermsConditions
